#app-frame.login {
	.alert {
		margin-top: $appbarHeight;
		&.ng-enter, &.ng-leave {
		  -webkit-transition:0.25s linear all;
		  transition:0.25s linear all;
		  margin-top: 0;
		}
		&.ng-enter { 
			margin-top: 0;
			-webkit-transition-delay: .25s;
    		transition-delay: .25s;
		}/* starting animations for enter */
		&.ng-enter.ng-enter-active { margin-top: $appbarHeight; } /* terminal animations for enter */
		&.ng-leave { margin-top: $appbarHeight; }        /* starting animations for leave */
		&.ng-leave.ng-leave-active { margin-top: 0; } /* terminal animations for leave */
	}
	#appbar {
		height: 50%;
		margin-top: -100px;
		-webkit-transition: height 0.25s, margin 0.5s, -webkit-box-shadow 0.5s;
		-moz-transition: height 0.25s, margin 0.5s, -moz-box-shadow 0.5s;
		-ms-transition: height 0.25s, margin 0.5s, -ms-box-shadow 0.5s;
		transition: height 0.25s, margin 0.5s, box-shadow 0.5s;
		-moz-box-shadow: 0 0 0 rgba(0,0,0,0);
	    -webkit-box-shadow: 0 0 0 rgba(0,0,0,0);
        box-shadow: 0 0 0 rgba(0,0,0,0); 
		.logo {
			position: absolute;
			left: 0;
			right: 0;
			top: 50%;
			margin-top: -60px;
			.logo-inside {
				min-width: 100%;
				left: 0;
				img {
					width: 200px;
					margin: 0;
				}
			}
		}
		a.toggle-nav {
			opacity: 0;
			pointer-events: none;
		}
	}
	#login {
		  transform: translateY(0);
	}
	#content-wrap {
		#content {
			&.ng-enter, &.ng-leave {
			  -webkit-transition:0.25s linear all;
			  transition:.25s linear all;
			  opacity: 0;
			}
			&.ng-enter { 
				opacity: 0;
				-webkit-transition-delay: .5s;
	    		transition-delay: .5s;
			}/* starting animations for enter */
			&.ng-enter.ng-enter-active { opacity: 1; } /* terminal animations for enter */
			&.ng-leave { opacity: 1; }        /* starting animations for leave */
			&.ng-leave.ng-leave-active { opacity: 0; } /* terminal animations for leave */
		}
	}
}

#content-wrap {
	#content {
		&.ng-enter, &.ng-leave {
		  -webkit-transition:0.25s linear all;
		  transition:.25s linear all;
		  opacity: 1;
		}
		&.ng-enter { 
			opacity: 1;
			-webkit-transition-delay: .5s;
    		transition-delay: .5s;
		}/* starting animations for enter */
		&.ng-enter.ng-enter-active { opacity: 1; } /* terminal animations for enter */
		&.ng-leave { opacity: 1; }        /* starting animations for leave */
		&.ng-leave.ng-leave-active { opacity: 1; } /* terminal animations for leave */
		#module {
			&.ng-enter, &.ng-leave {
			  -webkit-transition:0.25s linear all;
			  transition:.25s linear all;
			  opacity: 1;
			}
			&.ng-enter { 
				opacity: 1;
				-webkit-transition-delay: .5s;
	    		transition-delay: .5s;
			}/* starting animations for enter */
			&.ng-enter.ng-enter-active { opacity: 1; } /* terminal animations for enter */
			&.ng-leave { opacity: 1; }        /* starting animations for leave */
			&.ng-leave.ng-leave-active { opacity: 0; } /* terminal animations for leave */		
		}
	}
}

#login {
	display: table;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 50;
	width: 100%;
	height: 100%;
	transform: translateY(100%);
	transition: .5s;
	.alert {
		padding-right: 70px;
	}
	.login-center {
		display: table-cell;
		width: 100%;
		height: 100%;
		text-align: center;
		vertical-align: middle;
		.inside {
			text-align: left;
			background-color: white;
			box-shadow: $shadow-layer-1;
			width: 35%;
			display: inline-block;
			.login-body {
				padding: 50px;
				h2 {
					padding: 0 0 40px 0;
					margin: 0;
					font-size: 38px;
					font-weight: 200;
					text-rendering: optimizeLegibility;
					-webkit-font-smoothing: antialiased;
					color: $gray;
				}
				.field-wrap {
					.input-wrap {
						position: relative;
						input {
							width: 100%;
							padding: 14px 0 10px 0;
							margin-bottom: 20px;
							border: 0;
							font-size: 18px;
							border-bottom-color: $gray-light;
							border-bottom-width: 1px;
							border-bottom-style: solid;
							font-weight: 200;
						    -webkit-transition: 0.25s linear border-color;
						    -moz-transition: 0.25s linear border-color;
							transition: 0.25s linear border-color;
							&:focus {
								border-bottom-color: $blue;
								border-bottom-width: 2px;
								margin-bottom: 19px;
							}
						}
					}
					.cb-wrap {
						margin-bottom: 0;
						margin-top: 20px;
						label {
							margin-bottom: 0;
						}
					}
				}
			}
			.actions {
				padding: 30px 40px;
				border-top: 1px solid $gray-light;
				text-align: right;
				input.btn {
					text-transform: uppercase;
					letter-spacing: 1px;
					font-weight: 600;
					display: inline-block;
					margin-left: 5px;
				}
				.forgot {
					display: inline-block;
				}
			}

		}
	}
	.help {
		position: absolute;
		right: 16%;
		bottom: 60px;
		left: 16%;
		color: $gray;
		h3 {
			font-weight: 600;
			font-size: 16px;
		}
	}

}

