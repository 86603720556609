%material-hover-btn {
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.18s cubic-bezier(.25, .8, .25, 1);

  &:hover {
    box-shadow: 0 5px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
}

%extra-btn-spacing {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#analysis_dashboard {
  background-color: #37a2db;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  #dashboard_status_bar {
    width: 375px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .status-text {
      color: #fff;
      text-align: center;
    }
  }

}

#dashboard-center-controls {
  margin-top: 100px;
  width: 300px;
  margin-left: auto;
  margin-right: auto;

  #dashboard-analyst-controls {
    margin-bottom: 50px;

    .input-group {
      border-radius: 2px;
      margin-bottom: 15px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      input {
        font-size: 14px;
        height: 30px;
        border-radius: 2px;
      }
    }

    .dash-controls-button-box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .extra-control-buttons {
        @extend %extra-btn-spacing;
        .load-btn {
          width: 48%;
          height: 45px;
        }
        .btn-warning {
          background: #f0ad4e;
        }
      }

      .load-btn {
        @extend %material-hover-btn;
        margin-bottom: 15px;
        height: 65px;
        background: #fff;
        display: inline-block;
        position: relative;
      }
    }
  }

  #dashboard-admin-controls {
    .extra-control-buttons {
      @extend %extra-btn-spacing;
      .btn {
        @extend %material-hover-btn;
        width: 48%;
        height: 45px;
        margin-bottom: 15px;
      }
      .btn-default {
        background: #fff;
      }
      .btn-warning {
        background: #f0ad4e;
      }
    }

  }
}
