#substitution-modal {
	#court {
		background-color: #333d48;
		color: white;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		padding: 15px 45px;

		.player-container {
			width: 33%;
			height: 50px;
			display:flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;

			&:hover {
				background-color: #3c4856;
			}

			&.selected {
				background-color: #455363;
			}

			.player-on-court {
				border-width: 2px;
				border-style: solid;
				border-color: white;
				padding: 2px 5px;
				width: 27px;
				height: 27px;
				text-align: center;
			}

			// Copied from _virtualCourtStyle.scss
			.middle {
				border-color: #a94442;
				color: #a94442;
			}

			.outside-hitter {
				border-color: #3c763d;
				color: #3c763d;
			}

			.opposite {
				border-color: #8a6d3b;
				color: #8a6d3b;
			}
			.setter {
				border-color: #36a1da;
				color: rgb(0, 255, 3);
			}
		  .trueOnCourtSetter {
		    webkit-border-radius: 50%;
		    -moz-border-radius: 50%;
		    border-radius: 50%;
		  }

			.defensive-specialist {
				border-color: #36a1da;
				color: #36a1da;
				border: 0px;
			}

			.libero {
				border-color: #f2f2f2;
				color: #f2f2f2;
				border: 0px;
			}
		}

		#net-top {
			width: 100%;
			text-align: center;
			border-bottom: 5px solid #2e2c33;
		}
		#net-bottom {
			width: 100%;
			text-align: center;
			border-top: 5px solid #2e2c33;
		}
	}
}
