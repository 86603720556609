#lineup-modal {
  .lineup-action-header-container {
    margin-bottom: 20px;
  }

  .load-current-portal-roster-button {
    float: right;
  }

  .set-number-change-container {

  }

  .set-number-change-button {
    float: right;
  }

	.modal-footer-compact {
		text-align: right;
	}

	.jersey-input {
		padding-left: 8px;
	}

	.compact-cell {
		padding: 0;
	}
}
