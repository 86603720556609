
#logo-slogan {
  max-width: 900px;
  width: 90%;
  padding-top: 70px;
  img {
    max-width: 386px;
  }
  h4 {
    margin-top: -5px;
    margin-bottom: 50px;
  }
}

#login-page-content {
  max-width: 900px;
  width: 90%;
  .main-section {
    padding: 16px;
  }
}

#login-section, #blog-section {
  height: 400px;
}

#login-section {
  padding: 60px 36px;
}
//
//#blog-section {
//  padding: 36px 36px;
//  &:after {
//    content: '';
//    position: absolute;
//    border-style: solid;
//    border-width: 22px 25px 22px 0;
//    border-color: transparent #36a1da;
//    display: block;
//    width: 0;
//    z-index: 1;
//    margin-top: -22px;
//    left: -25px;
//    top: 50%;
//  }
//  &:before {
//    content: '';
//    position: absolute;
//    border-style: solid;
//    border-width: 23px 26px 23px 0;
//    border-color: transparent #ffffff;
//    display: block;
//    width: 0;
//    z-index: 0;
//    margin-top: -23px;
//    left: -28px;
//    top: 50%;
//  }
//}
//
//#more-news {
//  bottom: 36px;
//  right: 36px;
//}

//@media screen and (max-width: 948px) {
//  .blog-post p {
//    height: 38px;
//    overflow: hidden;
//  }
//}
//
//@media screen and (max-width: 767px) {
//  #blog-section {
//    &:after {
//      content: '';
//      position: absolute;
//      border-style: solid;
//      border-width: 0 22px 25px;
//      border-color: #36a1da transparent;
//      display: block;
//      width: 0;
//      z-index: 1;
//      top: -3px;
//      left: 50%;
//      margin-left: -22px;
//    }
//    &:before {
//      content: '';
//      position: absolute;
//      border-style: solid;
//      border-width: 0 23px 26px;
//      border-color: #ffffff transparent;
//      display: block;
//      width: 0;
//      z-index: 0;
//      top: -5px;
//      left: 50%;
//      margin-left: -23px;
//    }
//
//  }
//}
