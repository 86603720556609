
	/**
	 * These are the variables used throughout the application. This is where 
	 * overwrites that are not specific to components should be maintained.
	 */

	$module-horz-padding: 20px;

	$grid-gutter-width: 20px !default;
	$line-height-base: 1.5 !default;

	/**
	 * Typography-related.
	 */

	$sansFontFamily: 'Lato', sans-serif;

	// Colors

	$blue: #37a2db;
	$turquoise: #0ACCA6;
	$green: #7ECC69;
	$yellow: #FFC033;
	$red: #ED594A;
	$purple: #9C72B7;
	//$gray: #606063;
	$offWhite: #F5F7F7;


	$gray-darker:            #222223;
	$gray-dark:              #38383a;
	$gray:                   #777b7c;
	$gray-light:             #e4e7e9;
	$gray-lighter:           #f4f6f6;

	$brand-primary:         $blue !default;
	$brand-success:         $green !default;
	$brand-info:            $purple !default;
	$brand-warning:         $yellow !default;
	$brand-danger:          $red !default;

	$bodyBgColor: $gray-light;

	$navWidth: 0;
	$navBgColor: $blue;
	$appbarColor: $blue;
	$toolbarColor: white;
	$sidebarWidth: 400px;

	$appbarHeight: 60px;
	$modulebarHeight: 60px;
	$toolbarHeight: 70px;

	$font-family: "Lato", sans-serif;

	$line-height-base:        1.25 !default; // 20/14

	$border-radius-base:        2px !default;
	$border-radius-large:       3px !default;
	$border-radius-small:       1px !default;

	// dash board panel padding
	$dash-panel-padding: 0.2em;


	// Material design depth shadows

	$shadow-layer-1: 0 1.5px 4px rgba(0, 0, 0, 0.24), 0 1.5px 6px rgba(0, 0, 0, 0.12);
	$shadow-layer-2: 0 3px 12px rgba(0, 0, 0, 0.23), 0 3px 12px rgba(0, 0, 0, 0.16);
	$shadow-layer-3: 0 6px 12px rgba(0, 0, 0, 0.23), 0 10px 40px rgba(0, 0, 0, 0.19);
	$shadow-layer-4: 0 10px 20px rgba(0, 0, 0, 0.22), 0 14px 56px rgba(0, 0, 0, 0.25);
	$shadow-layer-5: 0 15px 24px rgba(0, 0, 0, 0.22), 0 19px 76px rgba(0, 0, 0, 0.3);


