videogular {
	vg-controls {
		top: auto;
		bottom: 30px;
		width: auto;
		left: 30px;
		right: 30px;
		&[vg-autohide=true] {
			.controls-container {
				background-color: rgba(38,50,56,0.8);
			}
		}
		.controls-container {
			background-color: rgba(38,50,56,0.8);
		    -moz-border-radius: 3px;
	        -webkit-border-radius: 3px;
	        border-radius: 3px;
	        -moz-box-shadow: $shadow-layer-2;
	        -webkit-box-shadow: $shadow-layer-2;
	        box-shadow: $shadow-layer-2; 
	        vg-scrub-bar {
	        	[role=slider] {
		        	height: 6px;
		        	background-color: rgba(0,0,0,0.5);
		        	outline: none;
	        	    -moz-border-radius: 6px;
				    -webkit-border-radius: 6px;
		            border-radius: 6px; 
					vg-scrub-bar-current-time {
						background-color: $blue;
		        	    -moz-border-radius: 6px;
					    -webkit-border-radius: 6px;
			            border-radius: 6px;
			            position: relative;
			            &:after {
							content: '';
							display: block;
							background-color: white;
							width: 16px;
							height: 16px;
						    -moz-border-radius: 50%;
						    -webkit-border-radius: 50%;
				            border-radius: 50%; 
				            -moz-box-shadow: $shadow-layer-1;
					        -webkit-box-shadow: $shadow-layer-1;
					        box-shadow: $shadow-layer-1; 
					        position: absolute;
					        top: 50%;
					        right: -8px;
					        margin-top: -8px;
			            }
					}	        		
	        	}
	        }
	        vg-time-display {
	        	font-size: 16px;
	        }
	        .iconButton {
				color: rgba(255,255,255,.4);
				font-family: 'md-icons';
				speak: none;
				font-style: normal;
				font-weight: 400;
				font-variant: normal;
				text-transform: none;
				line-height: 1;
				-webkit-font-smoothing: antialiased;
				background: 0 0;
				padding: 6px;
				border: none;
				font-size: 32px;
			}
			vg-play-pause-button {
				.iconButton {
					padding: 6px 6px 6px 12px;
					&.play:before {
						content: "\f0b9";
					}
					&.pause:before {
						content: "\f0b6";
					}
				}
			}
			vg-fullscreen-button {
				.iconButton {
					&.enter:before {
						content: "\f29f";
					}
					&.exit:before {
						content: "\f2a0";
					}
				}
			}
			vg-mute-button {
				.iconButton {
					&.mute:before {
						content: "\f0d1";
					}
					&.level0:before {
						content: "\f0d0";
					}
					&.level1:before {
						content: "\f0cf";
					}
					&.level2:before {
						content: "\f0d2";
					}
					&.level3:before {
						content: "\f0d2";
					}
				}
			}
			vg-volume-bar {
				width: 30px;
				height: 100px;
				top: -100px;
				margin-left: -15px;
				vertical-align: middle;
				text-align: center;
				position: absolute;
				cursor: pointer;
				.verticalVolumeBar {
					width: 30px;
					height: 100px;
					background-color: rgba(38,50,56, 0.9);
					position: absolute;
					-moz-border-radius: 6px 6px 0 0;
				    -webkit-border-radius: 6px 6px 0 0;
		            border-radius: 6px 6px 0 0;
					.volumeBackground {
						width: 6px;
						height: 70px;
						left: 12px;
						top: 15px;
						background-color: rgba(0, 0, 0, 0.5);
						position: absolute;
		        	    -moz-border-radius: 6px;
					    -webkit-border-radius: 6px;
			            border-radius: 6px;
			            .volumeValue {
							width: 6px;
							height: 100%;
							background-color: #FFF;
							position: absolute;
			        	    -moz-border-radius: 6px;
						    -webkit-border-radius: 6px;
				            border-radius: 6px;
			            }
						.volumeClickArea {
							width: 6px;
						}
					}
				}
			}
	    }
	}
	vg-overlay-play {
		.play:before {
			font-family: 'md-icons';
			content: "\f0bb";
		}
	}
}

@keyframes showControlsAnimationFrames {
  0% {
    background-color: transparent; }
  100% {
    background-color: rgba(38,50,56,0.8); } }
@-moz-keyframes showControlsAnimationFrames {
  0% {
    background-color: transparent; }
  100% {
    background-color: rgba(38,50,56,0.8); } }
@-webkit-keyframes showControlsAnimationFrames {
  0% {
    background-color: transparent; }
  100% {
    background-color: rgba(38,50,56,0.8); } }
@-o-keyframes showControlsAnimationFrames {
  0% {
    background-color: transparent; }
  100% {
    background-color: rgba(38,50,56,0.8); } }
@-ms-keyframes showControlsAnimationFrames {
  0% {
    background-color: transparent; }
  100% {
    background-color: rgba(38,50,56,0.8); } }