#virtual-court {

  #player-numbers-position {
    width: 41%;
    height: 56%;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0px;
    left: 0px;
    right: 0px;
  }

  #far-court {
    height: 42%;
    width: 100%;
  }

  #far-back {
    width: 72%;
    height: 50%;
    margin: auto;
  }

  #far-front {
    width: 78%;
    height: 50%;
    margin: auto;
  }

  #near-court {
    height: 58%;
    width: 100%;
  }

  #near-back {
    width: 96%;
    height: 50%;
    margin: auto;
  }

  #near-front {
    width: 85%;
    height: 50%;
    margin: auto;
  }

  .indoor-court-zone {
    float: left;
    width: 33.3333%;
    height: 100%;
    position: relative;
  }

  .beach-court-zone {
    float: left;
    width: 50%;
    height: 100%;
    position: relative;
  }

  .court-zone-player {
    width: 27px;
    height: 27px;
    border: 2px solid #ffffff;
    color: #ffffff;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    text-align: center;
    line-height: 22px;
    font-size: 14px;
  }

  .middle {
    border-color: #a94442;
    color: #a94442;
  }

  .outside-hitter {
    border-color: #3c763d;
    color: #3c763d;
  }

  .opposite {
    border-color: #8a6d3b;
    color: #8a6d3b;
  }
  /* .trueOnCourtSetter tracks the actual setter on the court*/
  .trueOnCourtSetter {
    border-color: #36a1da;
    color: #36a1da;
    webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
  }
  /* .setter tracks the setter rotation player*/
  .setter {
    color: rgb(0, 255, 3);
  }

  .defensive-specialist {
    border-color: #36a1da;
    color: #36a1da;
    border: 0px;
  }

  .libero {
    border-color: #f2f2f2;
    color: #f2f2f2;
    border: 0px;
  }

  .defender {
    // TODO: Decide what this should look like.
    border-color: #36a1da;
    color: #36a1da;
    webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
  }

  .universal {
    // TODO: Decide what this should look like.
  }

  .blocker {
    // TODO: Decide what this should look like.
  }
}
