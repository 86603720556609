div#analysis-pages {
  a,
  a:focus,
  a:active,
  a:focus:active,
  input,
  input:focus,
  input:active,
  input:focus:active,
  button,
  button:focus,
  button:focus:active,
  .btn:active,
  .btn:focus,
  .btn:active,
  .btn:focus:active {
    outline: 0;
    outline: none;
  }

  .inline {
    display: inline-block;
  }

  #module {
    section {
      margin-bottom: 20px;
      header {
        padding: 10px $module-horz-padding;
        h3 {
          color: $gray;
          font-size: 13px;
          text-transform: uppercase;
          font-weight: 600;
          letter-spacing: 0.15em;
          line-height: 2em;
          margin: 0;
        }
      }
      footer {
        background: $gray-lighter;
        border-radius: 0;
        padding: 10px 20px;
      }
      .section-body {
        padding: 0;
        background-color: $offWhite;
        border-radius: 0;
        .row {
          padding: 10px $module-horz-padding;
          background-color: white;
          border-bottom: 1px solid #E1E2E2;
          display: table;
          margin: 0;
          width: 100%;
          > div {
            display: table-cell;
            float: none;
            vertical-align: middle;
          }
          .row {
            padding: 0;
            margin: 0;
            margin-left: -$grid-gutter-width/2;
            margin-right: -$grid-gutter-width/2;
            width: auto;
          }

          p:last-child {
            margin-bottom: 0;
          }
          & > div:first-child {
            padding-left: 0;
          }
          & > div:last-child {
            padding-right: 0;
          }
        }
        .group {
          .group-title {
            background-color: $gray-lighter;
            padding: 20px $module-horz-padding;
            h4 {
              font-size: 14px;
              font-weight: 700;
              margin: 0;
              color: $gray-dark;
            }
          }
          //border-bottom: 1px solid #E1E2E2;
          //padding: 10px 0;
        }
        .group:last-child {
          //border-bottom: 0;
        }
        > table {
          width: 100%;
          thead {
            tr {
              th {
                padding: 10px 5px;
                background: $gray;
                color: white;
                &:first-child {
                  padding-left: $module-horz-padding;
                }
                &:last-child {
                  padding-right: $module-horz-padding;
                }
              }
            }
          }
          tbody {
            tr {
              td {
                padding: 15px 5px;
                background-color: white;
                border-bottom: 1px solid #E1E2E2;
                &:first-child {
                  padding-left: $module-horz-padding;
                }
                &:last-child {
                  padding-right: $module-horz-padding;
                }
                .play.btn {

                }
              }
            }
          }
        }
      }

      .fields {
        .row {
          border: 0;
          > div {
            padding-left: $grid-gutter-width/4;
            padding-right: $grid-gutter-width/4;
            .btn {
              width: 100%;
            }
          }
          > div:first-child {
            padding-left: $grid-gutter-width/2;
          }
          > div:last-child {
            padding-right: $grid-gutter-width/2;
          }
        }
      }

      input[type="text"],
      input[type="password"],
      input[type="number"],
      textarea {
        padding: 6px 0px;
        background: transparent;
        border-radius: 3px;
        border: 0;
        outline: none;
        box-shadow: 0 0 0 transparent;
        width: 100%;
        line-height: 1.5;
        resize: none;
        min-height: 35px;
      }
      input[type="password"] {
        font: small-caption;
        font-size: 16px;
        &::-webkit-input-placeholder {
          font-family: $font-family;
          font-size: 14px;
          font-weight: 400;
        }
        &:-moz-placeholder {
          /* Firefox 18- */
          font-family: $font-family;
          font-size: 14px;
          font-weight: 400;
        }
        &::-moz-placeholder {
          /* Firefox 19+ */
          font-family: $font-family;
          font-size: 14px;
          font-weight: 400;
        }
        &:-ms-input-placeholder {
          font-family: $font-family;
          font-size: 14px;
          font-weight: 400;
        }
      }
      textarea {
        min-height: 100px;
      }
      input[type="text"]:focus,
      input[type="password"]:focus,
      input[type="number"]:focus,
      textarea:focus {
        border: 0;
      }
      .dropdown-menu {
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.05);
        right: 10px;
      }

      .ui-datepicker-container {
        &:after {
          content: '\e618';
          font-family: Simplicicons;
          font-size: 18px;
          color: $gray;
          position: absolute;
          right: 18px;
          top: 4px;
          z-index: 2;
          pointer-events: none;
          font-weight: bold;
        }
        .dropdown-menu {
          right: auto;
          table {
            margin: 10px;
            th {
              button {
                margin-bottom: 10px;
                border: 0;
                strong {
                  font-size: 18px;
                  font-weight: 400;
                }
                i.glyphicon.glyphicon-chevron-left:before {
                  content: '\e6db';
                  font-family: 'Simplicicons';
                  font-size: 24px;
                }
                i.glyphicon.glyphicon-chevron-right:before {
                  content: '\e6dc';
                  font-family: 'Simplicicons';
                  font-size: 24px;
                }
              }
            }
            td {
              button {
                border: 0;
                &.btn-info {
                  background-color: $blue;
                }
              }
            }
          }
        }

      }

      .ui-select-container {
        outline: none;
        width: 100%;
        line-height: 1.5;
        position: relative;
        &:after {
          content: '\e6da';
          font-family: 'Simplicicons';
          font-size: 24px;
          color: $gray;
          position: absolute;
          right: 5px;
          bottom: 0;
          z-index: 2;
          pointer-events: none;
        }
        &:hover {
          button.btn {
            border-color: #C1C2C3;
          }
        }
        &.ui-select-multiple {
          border: 0;
          box-shadow: 0 0 0 transparent;
          padding: 3px 0;
          min-height: 35px;
          input[type="text"] {
            border: 0;
            padding: 0;
            min-height: 23px;
            padding: 6px 0;
            line-height: 1.5;
            min-height: 28px;
            &[placeholder]:not([placeholder=""]) {
              //min-height: 35px;
            }
          }
          &.open {
            border: 0;
          }
          .ui-select-match-item {
            height: 22px;
            margin-top: 3px;
            margin-bottom: 3px;
            background-color: $gray-lighter;
            outline: none;
            box-shadow: 0 0 0 transparent;
            margin-right: 6px !important;
            &.btn-primary {
              background-color: $blue;
            }
            .ui-select-match-close {
              line-height: 1;
            }
          }
        }
        .btn-group {
          box-shadow: 0 0 0 transparent;
          outline: none;
          button.btn {
            border: 0;
            padding: 6px 0;
            line-height: 1.5;
            text-align: left;
            font-size: 14px;
            background-color: white;
            outline: none;
            box-shadow: 0 0 0 transparent;
            min-height: 35px;
            &:first-child {
              border-right: 0;
            }
            &:last-child {
              border-left: 0;
            }
          }
          .caret {
            border-color: transparent;
          }
        }
        .text-muted {
          color: #999;
        }

        .ui-select-choices-row {
          // &.ng-enter, &.ng-leave {
          //   -webkit-transition: .25s linear all;
          //   transition: .25s linear all;
          //   opacity: 0;
          // }
          // &.ng-enter {
          // 	opacity: 0;
          // }/* starting animations for enter */
          // &.ng-enter.ng-enter-active { opacity: 1; } /* terminal animations for enter */
          // &.ng-leave { opacity: 1; }        /* starting animations for leave */
          // &.ng-leave.ng-leave-active { opacity: 0; } /* terminal animations for leave */

          &.active > a {
            background-color: $blue;
          }
        }
      }

      .ui-slider-container {
        slider {
          margin: 20px 0px;
        }
        .bar {
          height: 5px;
          background-color: #999;
          .selection {
            height: 5px;
            background-color: $blue;
          }
        }
        .handle {
          border: 0;
          box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
          transition: .1s;
          &:after {
            background-color: transparent;
          }
          &:hover, &:active {
            & ~ .bubble.value {
              display: inline-block;
            }
          }
        }
        .bubble.value {
          background-color: rgba(0, 0, 0, 0.75);
          color: white;
          top: -30px;
          padding: 2px 5px;
          border-radius: 3px;
          transition: .1s;
        }

      }
      &.script {
        background-color: $gray;
        color: white;
        position: relative;
        header {
          padding-bottom: 0;
          h3 {
            text-transform: uppercase;
            color: white;
            &:before {
              content: "\e64a";
              font-family: Simplicicons;
              display: inline-block;
              font-size: 25px;
              font-weight: normal;
              line-height: 40px;
              vertical-align: middle;
              margin-right: 8px;
            }
          }
        }
        .method {
          position: absolute;
          color: $gray-lighter;
          top: 20px;
          right: $module-horz-padding;
        }
        .section-body {
          padding: 20px $module-horz-padding;
          background-color: transparent;
          p {
            font-family: Georgia, serif;
            font-style: italic;
            font-size: 16px;
          }
        }
      }

    }
  }

  .ui-select-choices {
    // &.ng-enter, &.ng-leave {
    //   -webkit-transition: .25s linear all;
    //   transition: .25s linear all;
    //   opacity: 0;
    // }
    // &.ng-enter {
    // 	opacity: 0;
    // }/* starting animations for enter */
    // &.ng-enter.ng-enter-active { opacity: 1; } /* terminal animations for enter */
    // &.ng-leave { opacity: 1; }        /* starting animations for leave */
    // &.ng-leave.ng-leave-active { opacity: 0; } /* terminal animations for leave */
    transition: 1s linear all;
    &.ng-hide-add, &.ng-hide-remove {
      /* this is required as of 1.3x to properly
         apply all styling in a show/hide animation */
      transition: 1s linear all;
    }

    &.ng-hide-add-active,
    &.ng-hide-remove-active {
      /* the transition is defined in the active class */
      transition: 1s linear all;
    }

    &.ng-hide-add {
      opacity: 0;
    }
    &.ng-hide-add.ng-hide-add-active {
      opacity: 1;
    }
    &.ng-hide-remove {
      opacity: 1;
    }
    &.ng-hide-remove.ng-hide-remove-active {
      opacity: 0;
    }

  }

  .tooltip {
    .tooltip-inner {
      background: rgba($gray-dark, .8);
    }
    &.top {
      .tooltip-arrow {
        border-top-color: rgba($gray-dark, .8);
      }
    }
    &.right {
      .tooltip-arrow {
        border-right-color: rgba($gray-dark, .8);
      }
    }
    &.bottom {
      .tooltip-arrow {
        border-bottom-color: rgba($gray-dark, .8);
      }
    }
    &.left {
      .tooltip-arrow {
        border-left-color: rgba($gray-dark, .8);
      }
    }
  }

  .dropdown-menu {
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.05);
    right: 10px;
  }

  .btn {
    //border: 1px solid rgba(0,0,0,0.1);
    border: 0;
    font-size: 13px;
    transition: .1s;
    text-transform: uppercase;
    font-weight: 600;
    padding: 10px 21px;
    &.btn-default {
      color: $gray;
    }
    &.btn-lg {
      font-size: 15px;
    }
    &:hover {
      //border: 1px solid rgba(0,0,0,0.1);
    }
    &:focus {
      //border: 1px solid rgba(0,0,0,0.5);
    }
    &:active {
      //border: 1px solid rgba(0,0,0,0.1);
      box-shadow: 0 0 0 transparent;
    }
    &.btn-default:hover {
      //background-color: lighten($gray-light, 3%);
    }
    &.btn-primary:hover,
    &.btn-primary:focus {
      background-color: lighten($blue, 3%);
    }
    &.btn-success:hover,
    &.btn-success:focus {
      background-color: lighten($green, 4%);
    }
    &.btn-warning:hover,
    &.btn-warning:focus {
      background-color: lighten($yellow, 7%);
    }
    &.btn-danger:hover,
    &.btn-danger:focus {
      background-color: lighten($red, 5%);
    }
    // &.btn-primary:focus {
    // 	background-color: $blue;
    // }
    // &.btn-success:focus {
    // 	background-color: $green;
    // }
    // &.btn-warning:focus {
    // 	background-color: $yellow;
    // }
    // &.btn-danger:focus {
    // 	background-color: $red;
    // }
    // &.btn-info:focus {
    // 	background-color: $purple;
    // }
    &.btn-primary:active {
      background-color: darken($blue, 7%);
    }
    &.btn-success:active {
      background-color: darken($green, 6%);
    }
    &.btn-warning:active {
      background-color: darken($yellow, 3%);
    }
    &.btn-danger:active {
      background-color: darken($red, 5%);
    }
    &.btn-info:active {
      background-color: darken($purple, 6%);
    }

  }

  input[type=radio],
  input[type=checkbox] {
    display: none;
  }

  input[type=radio] ~ label,
  input[type=checkbox] ~ label {
    padding-left: 1.75em;
    position: relative;
    margin-bottom: .5em;
    font-weight: normal;
    margin-right: .5em;
    -webkit-transition: .2s;
    -moz-transition: .2s;
    -ms-transition: .2s;
    transition: .2s;
    // &:hover {
    // 	color: $blue;
    // }
  }
  input[type=radio] ~ label:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: 1.25em;
    height: 1.25em;
    margin-top: -0.625em;
    background-color: white;
    border: 2px solid $gray;
    -moz-border-radius: 2em;
    -webkit-border-radius: 2em;
    border-radius: 2em;
    -webkit-transition: .2s;
    -moz-transition: .2s;
    -ms-transition: .2s;
    transition: .2s;
  }

  input[type=radio] ~ label:active:before {
    background-color: #F7F7F9;
  }
  input[type=radio] ~ label:hover:before {
    border-color: #ACAEAF;
  }

  input[type=radio] ~ label:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: 1.25em;
    height: 1.25em;
    margin-top: -0.625em;
    background-color: white;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    opacity: 0;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transition: .2s;
    -moz-transition: .2s;
    -ms-transition: .2s;
    transition: .2s;
  }

  input[type=radio]:checked ~ label:before {
    background-color: $gray;
  }
  input[type=radio]:checked ~ label:hover:before {
    background-color: $blue;
  }
  input[type=radio]:checked ~ label:after {
    opacity: 1;
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    transform: scale(0.5);
  }

  input[type=checkbox] ~ label:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: 1.25em;
    height: 1.25em;
    margin-top: -0.625em;
    background-color: white;
    border: 2px solid $gray;
    border-radius: .2em;
    transition: .2s;
  }

  input[type=checkbox] ~ label:active:before {
    background-color: #F7F7F9;
  }
  input[type=checkbox] ~ label:hover:before {
    border-color: $blue;
  }

  input[type=checkbox] ~ label:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: .75em;
    height: 1.5em;
    border-right: .4em solid white;
    border-bottom: .4em solid white;
    margin-top: -.85em;
    margin-left: 0.25em;
    background-color: transparent;
    opacity: 0;
    -webkit-transform: scale(0) rotate(45deg);
    -moz-transform: scale(0) rotate(45deg);
    -ms-transform: scale(0) rotate(45deg);
    transform: scale(0) rotate(45deg);
    -webkit-transition: .2s;
    -moz-transition: .2s;
    -ms-transition: .2s;
    transition: .2s;
  }

  input[type=checkbox]:checked ~ label:before {
    background-color: $gray;
  }
  input[type=checkbox]:checked ~ label:hover:before {
    background-color: $blue;
  }
  input[type=checkbox]:checked ~ label:after {
    opacity: 1;
    -webkit-transform: scale(0.5) rotate(45deg);
    -moz-transform: scale(0.5) rotate(45deg);
    -ms-transform: scale(0.5) rotate(45deg);
    transform: scale(0.5) rotate(45deg);
  }

  .toggle-control {
    input[type=radio] ~ label:before,
    input[type=radio] ~ label:after {
      display: none;
    }
    .option {
      display: inline-block;
      width: 50%;
      &:first-child {
        input[type=radio] ~ label {
          border-radius: .2em 0 0 .2em;
        }
      }
      &:last-child {
        margin-left: -.35em;
        input[type=radio] ~ label {
          border-radius: 0 .2em .2em 0;
        }
      }
      &.success {
        input[type=radio]:checked ~ label {
          background-color: $green;
        }
      }
      &.danger {
        input[type=radio]:checked ~ label {
          background-color: $red;
        }
      }
    }
    input[type=radio] ~ label {
      background-color: white;
      border: 1px solid #E1E2E2;
      text-align: center;
      padding: 6px 10px;
      margin: 0;
      width: 100%;
      cursor: pointer;
      transition: .2s;
      &:hover {
        background-color: #f9f9f9;
      }
      &:active {
        background-color: $gray-lighter;
      }
    }
    input[type=radio]:checked ~ label {
      background-color: $blue;
      color: white;
      cursor: default;
    }
  }

  .row > div > .btn {
    width: 100%;
  }

  .alert {
    padding-left: 50px;
    padding-right: 200px;
    position: relative;
    border: 0;
    border-radius: 0;
    &:before {
      content: '\e6f0';
      display: block;
      position: absolute;
      top: 50%;
      font-family: 'Simplicicons';
      font-size: 25px;
      left: 12px;
      margin-top: -18px;
    }
    &.alert-success, &.alert-info, &.alert-warning, &.alert-danger, &.alert-question {
      color: white;
    }
    &.alert-success {
      background-color: $brand-success;
      &:before {
        content: '\e6f0'
      }
    }
    &.alert-info {
      background-color: $brand-primary;
      &:before {
        content: '\e6f8'
      }
    }
    &.alert-warning {
      background-color: $brand-warning;
      &:before {
        content: '\e6fb'
      }
    }
    &.alert-danger {
      background-color: $brand-danger;
      &:before {
        content: '\e6ed'
      }
    }
    &.alert-question {
      background-color: $brand-info;
      &:before {
        content: '\e6f9'
      }
    }
    .action {
      position: absolute;
      right: 50px;
      top: 0;
      bottom: 0;
      max-width: 150px;
      padding: 15px 20px;
      color: white;
      background: transparent;
      border: none;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      text-align: center;
      transition: .2s;
      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
        text-decoration: none;
      }
      &:active {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
    .close {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 50px;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      opacity: 1;
      text-shadow: 0 0 0 transparent;
      font-weight: 200;
      font-size: 24px;
      color: rgba(0, 0, 0, 0.33);
      transition: .2s;
      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
        text-decoration: none;
      }
      &:active {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }

  .modal-backdrop {
    bottom: 0;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    background-color: #000;
  }

  .modal {
    display: table !important;
    width: 100%;
    height: 100%;
    text-align: center;
    vertical-align: middle;
    .modal-dialog {
      display: table-cell;
      width: 100%;
      height: 100%;
      text-align: center;
      vertical-align: middle;
      pointer-events: none;
      .modal-content {
        text-align: left;
        display: inline-block;
        min-width: 25%;
        max-width: 75%;
        background-color: white;
        pointer-events: all;
        .modal-header {
          background-color: white;
          border-radius: 0;
          // text-transform: uppercase;
          // letter-spacing: .15em;
          line-height: 2em;
          padding: 40px 40px 0 40px;
          border: 0;
          .modal-title {
            color: #555;
            font-size: 36px;
            font-weight: 200;
            line-height: 1;
          }
        }
        .modal-body {
          padding: 40px;
        }
        .modal-footer {
          background-color: white;
          padding: 15px;
        }
      }

      input[type="text"],
      input[type="password"],
      input[type="number"],
      textarea {
        padding: 6px 10px;
        border-radius: 3px;
        border: 1px solid #E1E2E2;
        outline: none;
        box-shadow: 0 0 0 transparent;
        width: 100%;
        line-height: 1.5;
        resize: none;
        min-height: 35px;
      }
      textarea {
        min-height: 100px;
      }
      input[type="text"]:focus,
      input[type="password"]:focus,
      input[type="number"]:focus,
      textarea:focus {
        border: 1px solid $blue;
      }
      .dropdown-menu {
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.05);
        right: 10px;
      }

      .ui-datepicker-container {
        &:after {
          content: '\e618';
          font-family: Simplicicons;
          font-size: 18px;
          color: #999;
          position: absolute;
          right: 18px;
          top: 4px;
          z-index: 2;
          pointer-events: none;
          font-weight: bold;
        }
        .dropdown-menu {
          right: auto;
          table {
            margin: 10px;
            th {
              button {
                margin-bottom: 10px;
                border: 0;
                strong {
                  font-size: 18px;
                  font-weight: 400;
                }
                i.glyphicon.glyphicon-chevron-left:before {
                  content: '\e6db';
                  font-family: 'Simplicicons';
                  font-size: 24px;
                }
                i.glyphicon.glyphicon-chevron-right:before {
                  content: '\e6dc';
                  font-family: 'Simplicicons';
                  font-size: 24px;
                }
              }
            }
            td {
              button {
                border: 0;
                &.btn-info {
                  background-color: $blue;
                }
              }
            }
          }
        }

      }

      .ui-select-container {
        outline: none;
        width: 100%;
        line-height: 1.5;
        position: relative;
        &:after {
          content: '\e6da';
          font-family: 'Simplicicons';
          font-size: 24px;
          color: $gray-light;
          position: absolute;
          right: 5px;
          bottom: 0;
          z-index: 2;
          pointer-events: none;
        }
        &:hover {
          button.btn {
            border-color: #C1C2C3;
          }
        }
        &.ui-select-multiple {
          border: 1px solid #E1E2E2;
          box-shadow: 0 0 0 transparent;
          padding: 5px 5px 3px 5px;
          min-height: 35px;
          input[type="text"] {
            border: 0;
            padding: 0;
            min-height: 23px;
            padding-left: 5px;
          }
          &.open {
            border: 1px solid $blue;
          }
          .ui-select-match-item {
            height: 22px;
            margin-bottom: 3px;
            background-color: $gray-lighter;
            outline: none;
            box-shadow: 0 0 0 transparent;
            &.btn-primary {
              background-color: $blue;
            }
            .ui-select-match-close {
              line-height: 1;
            }
          }
        }
        .btn-group {
          box-shadow: 0 0 0 transparent;
          outline: none;
          button.btn {
            border: 1px solid #E1E2E2;
            padding: 6px 10px;
            line-height: 1.5;
            text-align: left;
            font-size: 14px;
            background-color: white;
            outline: none;
            box-shadow: 0 0 0 transparent;
            &:first-child {
              border-right: 0;
            }
            &:last-child {
              border-left: 0;
            }
          }
          .caret {
            border-color: transparent;
          }
        }
        .text-muted {
          color: #999;
        }
        .ui-select-choices-row {
          &.active > a {
            background-color: $blue;
          }
        }
      }

      .ui-slider-container {
        slider {
          margin: 20px 0px;
        }
        .bar {
          height: 5px;
          background-color: #999;
          .selection {
            height: 5px;
            background-color: $blue;
          }
        }
        .handle {
          border: 0;
          box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
          transition: .1s;
          &:after {
            background-color: transparent;
          }
          &:hover, &:active {
            & ~ .bubble.value {
              display: inline-block;
            }
          }
        }
        .bubble.value {
          background-color: rgba(0, 0, 0, 0.75);
          color: white;
          top: -30px;
          padding: 2px 5px;
          border-radius: 3px;
          transition: .1s;
        }

      }

    }

    &.pane {
      display: block !important;
      //		position: fixed;
      overflow: visible;
      .modal-dialog {
        width: 30%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: auto;
        display: block;
        //width: 100%;
        //height: 100%;
        margin: 0;
        .modal-content {
          min-width: 100%;
          max-width: 100%;
          border: 0;
          height: 100%;
          -webkit-transition: -webkit-transform .3s ease-out;
          -moz-transition: -moz-transform .3s ease-out;
          -o-transition: -ms-transform .3s ease-out;
          transition: transform .3s ease-out;
          .modal-header {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
          }
          .modal-body {
            position: absolute;
            top: 50px;
            left: 0;
            right: 0;
            bottom: 50px;
          }
          .modal-footer {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
          }
        }
      }
      &.fade {
        opacity: 1;
        .modal-dialog {
          -webkit-transform: scale(1);
          -moz-transform: scale(1);
          -ms-transform: scale(1);
          transform: scale(1);
          .modal-content {
            -webkit-transform: translate(100%, 0);
            -moz-transform: translate(100%, 0);
            -ms-transform: translate(100%, 0);
            transform: translate(100%, 0);
          }
        }
      }
      &.in {
        opacity: 1;
        .modal-dialog {
          -webkit-transform: scale(1);
          -moz-transform: scale(1);
          -ms-transform: scale(1);
          transform: scale(1);
          .modal-content {
            -webkit-transform: translate(0, 0);
            -moz-transform: translate(0, 0);
            -ms-transform: translate(0, 0);
            transform: translate(0, 0);
          }
        }
      }
    }
    &.fade {
      .modal-dialog {
        -webkit-transform: scale(2);
        // -webkit-filter: blur(2px);
        -moz-transform: scale(2);
        -ms-transform: scale(2);
        transform: scale(2);
      }
    }
    &.in {
      .modal-dialog {
        -webkit-transform: scale(1);
        // -webkit-filter: blur(0px);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
      }
    }
  }

  .modal-backdrop {
    background-color: #21222D;
  }

  body #app-frame {
    -webkit-backface-visability: hidden;
    -moz-backface-visability: hidden;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: -webkit-transform .3s ease-out;
    -moz-transition: -moz-transform .3s ease-out;
    -ms-transition: -ms-transform .3s ease-out;
    transition: transform .3s ease-out;

  }
  body.modal-open #app-frame {
    -webkit-transform: scale(.95);
    // -webkit-filter: blur(2px);
    -moz-transform: scale(.95);
    -ms-transform: scale(.95);
    transform: scale(.95);
  }

  .panel {
    border: 0;
    background-color: $gray-lighter;
    .panel-heading {
      font-size: 13px;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: .15em;
      line-height: 2.25em;
      margin: 0;
      background-color: white;
      border-left: 3px solid $blue;
    }
    .panel-top, .size-h3 {
      padding: $dash-panel-padding;
    }
    .panel-body {
    }
    .panel-footer {
      background-color: white;
    }
  }

  .loader {
    margin: 6em auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: .5em solid rgba($gray, 0.2);
    border-right: .5em solid rgba($gray, 0.2);
    border-bottom: .5em solid rgba($gray, 0.2);
    border-left: .5em solid $gray;
    -webkit-animation: loading .5s infinite linear;
    animation: loading .5s infinite linear;
  }
  .loader,
  .loader:after {
    border-radius: 50%;
    width: 5em;
    height: 5em;
  }

  .loader-wrap {
    background-color: rgba($gray-light, 0.5);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    .loader {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -2.5em 0 0 -2.5em;
    }
    &.ng-enter, &.ng-leave {
      -webkit-transition: .25s linear all;
      transition: .25s linear all;
      opacity: 0;
    }
    &.ng-enter {
      opacity: 0;
    }
    /* starting animations for enter */
    &.ng-enter.ng-enter-active {
      opacity: 1;
    }
    /* terminal animations for enter */
    &.ng-leave {
      opacity: 1;
    }
    /* starting animations for leave */
    &.ng-leave.ng-leave-active {
      opacity: 0;
    }
    /* terminal animations for leave */

  }

  .panel-box {
    .panel-top {
      padding: 20px;
      text-align: center;
      i.ico {
        font-size: 50px;
      }
    }
    .panel-bottom {
      padding: 10px 20px;
      text-align: center;
      p {
        margin-bottom: 0;
        a {
          color: $gray;
        }
      }
    }
  }

  .text-muted {
    color: #a9a9a9;
  }

  @-webkit-keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

}

.prev-set:disabled, .next-set:disabled {
  color: #ccc;
}

.indicator.has-error {
  border-color: #E04848 !important;
  background-color: #E04848 !important;
}

.ui-select-bootstrap > .ui-select-choices {
  width: auto !important;
}

div#portal .suto-table-responsive .suto-table .tr .table-cell.ng-binding, .portal-modal .suto-table-responsive .suto-table .tr .table-cell.ng-binding {
  overflow: hidden;
  text-overflow: ellipsis;
}

.center-item {
  // overflow: hidden !important;

  .rallies {
    overflow: hidden !important;
    position: absolute;
    left: 0;
    right: 0;
    transition: bottom .25s ease;
    -webkit-transition: bottom .25s ease;
    -moz-transition: bottom .25s ease;
  }
}

// Header details styles
.edit-details {
  width: 800px;
  position: absolute;

  select {
    display: block;
    width: 100%;
    height: 30px;
    padding: 0;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  }

  i {
    cursor: pointer;
  }

  .prev-touch, .next-touch {
    position: absolute;
    font-size: 3em;
    color: #0F354A;
    top: 0;
  }

  .prev-touch {
    left: -45px;
  }

  .delete-touch {
    font-size: 1.2em;
    margin-top: 21px;
    color: #7F3232;
    position: absolute;
    right: -85px;
  }

  .next-touch {
    right: -45px;
  }

  label {
    color: white;
  }

  select:focus {
    outline-color: black;
  }
}

.current {
  background: #FFE7C2;
}

.disabled-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #C7C7C7;
  opacity: .5;
}
