#history-modal {

  .history-title {
    float: left;
  }

  .loading-text {
    color: $gray;
    text-align: center;
  }
  
  th {
    i {
      float: right;
    }
  }

  #history-cancel-btn {
    margin-right: 20px;
  }

}