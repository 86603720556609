
	@font-face {
		font-family: 'Simplicicons';
		src:url('fonts/Simplicicons.eot?1thu2m');
		src:url('fonts/Simplicicons.eot?#iefix1thu2m') format('embedded-opentype'),
			url('fonts/Simplicicons.woff?1thu2m') format('woff'),
			url('fonts/Simplicicons.ttf?1thu2m') format('truetype'),
			url('fonts/Simplicicons.svg?1thu2m#Simplicicons') format('svg');
		font-weight: normal;
		font-style: normal;
	}

	[class^="ico-"], [class*=" ico-"], .fc-button {
		font-family: 'Simplicicons';
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;

		/* Better Font Rendering =========== */
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	i.ico {
		font-style: normal;
		font-size: 1.5em;
	}

	.ico-phone:before {
		content: "\e600";
	}
	.ico-mobile-phone:before {
		content: "\e601";
	}
	.ico-tablet:before {
		content: "\e602";
	}
	.ico-notebook-computer:before {
		content: "\e603";
	}
	.ico-desktop-computer:before {
		content: "\e604";
	}
	.ico-television:before {
		content: "\e605";
	}
	.ico-mouse:before {
		content: "\e606";
	}
	.ico-keyboard:before {
		content: "\e607";
	}
	.ico-mic:before {
		content: "\e608";
	}
	.ico-paper-airplane:before {
		content: "\e609";
	}
	.ico-pencil:before {
		content: "\e60a";
	}
	.ico-pen:before {
		content: "\e60b";
	}
	.ico-paintbrush:before {
		content: "\e60c";
	}
	.ico-feather:before {
		content: "\e60d";
	}
	.ico-leaf:before {
		content: "\e60e";
	}
	.ico-paperclip:before {
		content: "\e60f";
	}
	.ico-envelope:before {
		content: "\e610";
	}
	.ico-envelope-open:before {
		content: "\e611";
	}
	.ico-envelope-remove:before {
		content: "\e612";
	}
	.ico-envelope-add:before {
		content: "\e613";
	}
	.ico-mailbox:before {
		content: "\e614";
	}
	.ico-outbox:before {
		content: "\e615";
	}
	.ico-inbox:before {
		content: "\e616";
	}
	.ico-calendar-date:before {
		content: "\e617";
	}
	.ico-calendar:before {
		content: "\e618";
	}
	.ico-calendar-remove:before {
		content: "\e619";
	}
	.ico-calendar-add:before {
		content: "\e61a";
	}
	.ico-user:before {
		content: "\e61b";
	}
	.ico-users:before {
		content: "\e61c";
	}
	.ico-user-new:before {
		content: "\e61d";
	}
	.ico-user-remove:before {
		content: "\e61e";
	}
	.ico-user-add:before {
		content: "\e61f";
	}
	.ico-user-circled:before {
		content: "\e620";
	}
	.ico-user-card:before {
		content: "\e621";
	}
	.ico-file:before {
		content: "\e622";
	}
	.ico-files:before {
		content: "\e623";
	}
	.ico-file-remove:before {
		content: "\e624";
	}
	.ico-file-add:before {
		content: "\e625";
	}
	.ico-file-note:before {
		content: "\e626";
	}
	.ico-file-notes:before {
		content: "\e627";
	}
	.ico-file-photo:before {
		content: "\e628";
	}
	.ico-file-camera:before {
		content: "\e629";
	}
	.ico-file-film:before {
		content: "\e62a";
	}
	.ico-file-play:before {
		content: "\e62b";
	}
	.ico-folder:before {
		content: "\e62c";
	}
	.ico-folder-open:before {
		content: "\e62d";
	}
	.ico-folders:before {
		content: "\e62e";
	}
	.ico-folder-remove:before {
		content: "\e62f";
	}
	.ico-folder-add:before {
		content: "\e630";
	}
	.ico-cart:before {
		content: "\e631";
	}
	.ico-cart-remove:before {
		content: "\e632";
	}
	.ico-cart-add:before {
		content: "\e633";
	}
	.ico-tag:before {
		content: "\e634";
	}
	.ico-tag-remove:before {
		content: "\e635";
	}
	.ico-tag-add:before {
		content: "\e636";
	}
	.ico-trash:before {
		content: "\e637";
	}
	.ico-correct:before {
		content: "\e638";
	}
	.ico-wrong:before {
		content: "\e639";
	}
	.ico-quote:before {
		content: "\e63a";
	}
	.ico-windows:before {
		content: "\e63b";
	}
	.ico-settings:before {
		content: "\e63c";
	}
	.ico-cog:before {
		content: "\e63d";
	}
	.ico-wrench:before {
		content: "\e63e";
	}
	.ico-magnifying-glass:before {
		content: "\e63f";
	}
	.ico-zoom-out:before {
		content: "\e640";
	}
	.ico-zoom-in:before {
		content: "\e641";
	}
	.ico-eye:before {
		content: "\e642";
	}
	.ico-pin:before {
		content: "\e643";
	}
	.ico-map:before {
		content: "\e644";
	}
	.ico-compass:before {
		content: "\e645";
	}
	.ico-sign:before {
		content: "\e646";
	}
	.ico-location:before {
		content: "\e647";
	}
	.ico-crosshairs:before {
		content: "\e648";
	}
	.ico-home:before {
		content: "\e649";
	}
	.ico-conversation:before {
		content: "\e64a";
	}
	.ico-conversations:before {
		content: "\e64b";
	}
	.ico-box-out:before {
		content: "\e64c";
	}
	.ico-box-in:before {
		content: "\e64d";
	}
	.ico-share:before {
		content: "\e64e";
	}
	.ico-create-new:before {
		content: "\e64f";
	}
	.ico-thumbs-down:before {
		content: "\e650";
	}
	.ico-thumbs-up:before {
		content: "\e651";
	}
	.ico-reply:before {
		content: "\e652";
	}
	.ico-reply-all:before {
		content: "\e653";
	}
	.ico-forward:before {
		content: "\e654";
	}
	.ico-clipboard:before {
		content: "\e655";
	}
	.ico-power:before {
		content: "\e656";
	}
	.ico-battery-dead:before {
		content: "\e657";
	}
	.ico-battery-charged:before {
		content: "\e658";
	}
	.ico-battery-no-bar:before {
		content: "\e659";
	}
	.ico-battery-one-bar:before {
		content: "\e65a";
	}
	.ico-battery-two-bar:before {
		content: "\e65b";
	}
	.ico-battery-three-bar:before {
		content: "\e65c";
	}
	.ico-battery-charging:before {
		content: "\e65d";
	}
	.ico-feed:before {
		content: "\e65e";
	}
	.ico-wifi:before {
		content: "\e65f";
	}
	.ico-code:before {
		content: "\e660";
	}
	.ico-voicemail:before {
		content: "\e661";
	}
	.ico-print:before {
		content: "\e662";
	}
	.ico-save:before {
		content: "\e663";
	}
	.ico-database:before {
		content: "\e664";
	}
	.ico-cloud:before {
		content: "\e665";
	}
	.ico-cloud-upload:before {
		content: "\e666";
	}
	.ico-cloud-download:before {
		content: "\e667";
	}
	.ico-cloud-error:before {
		content: "\e668";
	}
	.ico-bolt:before {
		content: "\e669";
	}
	.ico-thermometer:before {
		content: "\e66a";
	}
	.ico-umbrella:before {
		content: "\e66b";
	}
	.ico-drop:before {
		content: "\e66c";
	}
	.ico-heart:before {
		content: "\e66d";
	}
	.ico-star:before {
		content: "\e66e";
	}
	.ico-moon:before {
		content: "\e66f";
	}
	.ico-sun:before {
		content: "\e670";
	}
	.ico-sun-sm:before {
		content: "\e671";
	}
	.ico-volume:before {
		content: "\e672";
	}
	.ico-volume-low:before {
		content: "\e673";
	}
	.ico-volume-mute:before {
		content: "\e674";
	}
	.ico-play:before {
		content: "\e675";
	}
	.ico-pause:before {
		content: "\e676";
	}
	.ico-record:before {
		content: "\e677";
	}
	.ico-stop:before {
		content: "\e678";
	}
	.ico-seek-prev:before {
		content: "\e679";
	}
	.ico-seek-next:before {
		content: "\e67a";
	}
	.ico-prev:before {
		content: "\e67b";
	}
	.ico-next:before {
		content: "\e67c";
	}
	.ico-note:before {
		content: "\e67d";
	}
	.ico-notes:before {
		content: "\e67e";
	}
	.ico-playlist:before {
		content: "\e67f";
	}
	.ico-cd:before {
		content: "\e680";
	}
	.ico-vinyl:before {
		content: "\e681";
	}
	.ico-turntable:before {
		content: "\e682";
	}
	.ico-headphones:before {
		content: "\e683";
	}
	.ico-film:before {
		content: "\e684";
	}
	.ico-video-camera:before {
		content: "\e685";
	}
	.ico-projector:before {
		content: "\e686";
	}
	.ico-ticket:before {
		content: "\e687";
	}
	.ico-video-play:before {
		content: "\e688";
	}
	.ico-game-controller:before {
		content: "\e689";
	}
	.ico-photos:before {
		content: "\e68a";
	}
	.ico-camera:before {
		content: "\e68b";
	}
	.ico-book:before {
		content: "\e68c";
	}
	.ico-newspaper:before {
		content: "\e68d";
	}
	.ico-clock:before {
		content: "\e68e";
	}
	.ico-alarm-clock:before {
		content: "\e68f";
	}
	.ico-watch:before {
		content: "\e690";
	}
	.ico-hourglass:before {
		content: "\e691";
	}
	.ico-stopwatch:before {
		content: "\e692";
	}
	.ico-trophy:before {
		content: "\e693";
	}
	.ico-lightbulb:before {
		content: "\e694";
	}
	.ico-bang:before {
		content: "\e695";
	}
	.ico-infinity:before {
		content: "\e696";
	}
	.ico-cross-out:before {
		content: "\e697";
	}
	.ico-flag:before {
		content: "\e698";
	}
	.ico-cone:before {
		content: "\e699";
	}
	.ico-speedometer:before {
		content: "\e69a";
	}
	.ico-magnet:before {
		content: "\e69b";
	}
	.ico-anchor:before {
		content: "\e69c";
	}
	.ico-lifesaver:before {
		content: "\e69d";
	}
	.ico-airplane:before {
		content: "\e69e";
	}
	.ico-rocket:before {
		content: "\e69f";
	}
	.ico-globe:before {
		content: "\e6a0";
	}
	.ico-spinning-globe:before {
		content: "\e6a1";
	}
	.ico-suitcase:before {
		content: "\e6a2";
	}
	.ico-briefcase:before {
		content: "\e6a3";
	}
	.ico-calculator:before {
		content: "\e6a4";
	}
	.ico-bag:before {
		content: "\e6a5";
	}
	.ico-gift:before {
		content: "\e6a6";
	}
	.ico-wallet:before {
		content: "\e6a7";
	}
	.ico-money-clip:before {
		content: "\e6a8";
	}
	.ico-credit-card:before {
		content: "\e6a9";
	}
	.ico-piggy-bank:before {
		content: "\e6aa";
	}
	.ico-cash:before {
		content: "\e6ab";
	}
	.ico-diamond:before {
		content: "\e6ac";
	}
	.ico-vault:before {
		content: "\e6ad";
	}
	.ico-unlocked:before {
		content: "\e6ae";
	}
	.ico-locked:before {
		content: "\e6af";
	}
	.ico-bar-chart:before {
		content: "\e6b0";
	}
	.ico-line-chart:before {
		content: "\e6b1";
	}
	.ico-pie-chart:before {
		content: "\e6b2";
	}
	.ico-donut-chart:before {
		content: "\e6b3";
	}
	.ico-bell:before {
		content: "\e6b4";
	}
	.ico-megaphone:before {
		content: "\e6b5";
	}
	.ico-stage-mic:before {
		content: "\e6b6";
	}
	.ico-key:before {
		content: "\e6b7";
	}
	.ico-heartbeat:before {
		content: "\e6b8";
	}
	.ico-stethoscope:before {
		content: "\e6b9";
	}
	.ico-glasses:before {
		content: "\e6ba";
	}
	.ico-underwear:before {
		content: "\e6bb";
	}
	.ico-boxers:before {
		content: "\e6bc";
	}
	.ico-shorts:before {
		content: "\e6bd";
	}
	.ico-bra:before {
		content: "\e6be";
	}
	.ico-toilet-paper:before {
		content: "\e6bf";
	}
	.ico-beaker:before {
		content: "\e6c0";
	}
	.ico-bucket:before {
		content: "\e6c1";
	}
	.ico-popsicle:before {
		content: "\e6c2";
	}
	.ico-link:before {
		content: "\e6c3";
	}
	.ico-ellipses:before {
		content: "\e6c4";
	}
	.ico-menu:before {
		content: "\e6c5";
	}
	.ico-list:before {
		content: "\e6c6";
	}
	.ico-tile:before {
		content: "\e6c7";
	}
	.ico-column:before {
		content: "\e6c8";
	}
	.ico-side-out:before {
		content: "\e6c9";
	}
	.ico-side-in:before {
		content: "\e6ca";
	}
	.ico-out:before {
		content: "\e6cb";
	}
	.ico-in:before {
		content: "\e6cc";
	}
	.ico-refresh:before {
		content: "\e6cd";
	}
	.ico-circle-arrow-left:before {
		content: "\e6ce";
	}
	.ico-circle-arrow-right:before {
		content: "\e6cf";
	}
	.ico-back-arrow:before {
		content: "\e6d0";
	}
	.ico-forward-arrow:before {
		content: "\e6d1";
	}
	.ico-shuffle:before {
		content: "\e6d2";
	}
	.ico-rotation-lock:before {
		content: "\e6d3";
	}
	.ico-rotation-locked:before {
		content: "\e6d4";
	}
	.ico-subtract:before {
		content: "\e6d5";
	}
	.ico-add:before {
		content: "\e6d6";
	}
	.ico-multiply:before {
		content: "\e6d7";
	}
	.ico-check:before {
		content: "\e6d8";
	}
	.ico-up-sm:before {
		content: "\e6d9";
	}
	.ico-down-sm:before {
		content: "\e6da";
	}
	.ico-left-sm:before {
		content: "\e6db";
	}
	.ico-right-sm:before {
		content: "\e6dc";
	}
	.ico-up-md:before {
		content: "\e6dd";
	}
	.ico-down-md:before {
		content: "\e6de";
	}
	.ico-left-md:before {
		content: "\e6df";
	}
	.ico-right-md:before {
		content: "\e6e0";
	}
	.ico-up-lg:before {
		content: "\e6e1";
	}
	.ico-down-lg:before {
		content: "\e6e2";
	}
	.ico-left-lg:before {
		content: "\e6e3";
	}
	.ico-right-lg:before {
		content: "\e6e4";
	}
	.ico-up-circled-md:before {
		content: "\e6e5";
	}
	.ico-down-circled-md:before {
		content: "\e6e6";
	}
	.ico-left-circled-md:before {
		content: "\e6e7";
	}
	.ico-right-circled-md:before {
		content: "\e6e8";
	}
	.ico-up-circled-sm:before {
		content: "\e6e9";
	}
	.ico-down-circled-sm:before {
		content: "\e6ea";
	}
	.ico-left-circled-sm:before {
		content: "\e6eb";
	}
	.ico-right-circled-sm:before {
		content: "\e6ec";
	}
	.ico-subtract-circled:before {
		content: "\e6ed";
	}
	.ico-add-circled:before {
		content: "\e6ee";
	}
	.ico-multiply-circled:before {
		content: "\e6ef";
	}
	.ico-check-circled:before {
		content: "\e6f0";
	}
	.ico-dollar:before {
		content: "\e6f1";
	}
	.ico-euro:before {
		content: "\e6f2";
	}
	.ico-pound:before {
		content: "\e6f3";
	}
	.ico-yen:before {
		content: "\e6f4";
	}
	.ico-bitcoin:before {
		content: "\e6f5";
	}
	.ico-copyright:before {
		content: "\e6f6";
	}
	.ico-closed-caption:before {
		content: "\e6f7";
	}
	.ico-info:before {
		content: "\e6f8";
	}
	.ico-question:before {
		content: "\e6f9";
	}
	.ico-alert:before {
		content: "\e6fa";
	}
	.ico-warning:before {
		content: "\e6fb";
	}
	.ico-smile-emoticon:before {
		content: "\e6fc";
	}
	.ico-wink-emoticon:before {
		content: "\e6fd";
	}
	.ico-eyes-shut-emoticon:before {
		content: "\e6fe";
	}
	.ico-tongue-emoticon:before {
		content: "\e6ff";
	}
	.ico-wink-tongue-emoticon:before {
		content: "\e700";
	}
	.ico-eyes-shut-tongue-emoticon:before {
		content: "\e701";
	}
	.ico-grin-emoticon:before {
		content: "\e702";
	}
	.ico-wink-grin-emoticon:before {
		content: "\e703";
	}
	.ico-eyes-shut-grin-emoticon:before {
		content: "\e704";
	}
	.ico-surprised-emoticon:before {
		content: "\e705";
	}
	.ico-serious-emoticon:before {
		content: "\e706";
	}
	.ico-expressionless-emoticon:before {
		content: "\e707";
	}
	.ico-sad-emoticon:before {
		content: "\e708";
	}
	.ico-tear-sad-emoticon:before {
		content: "\e709";
	}
	.ico-disguisted-emoticon:before {
		content: "\e70a";
	}
	.ico-angry-surprised-emoticon:before {
		content: "\e70b";
	}
	.ico-angry-serious-emoticon:before {
		content: "\e70c";
	}
	.ico-angry-sad-emoticon:before {
		content: "\e70d";
	}
	.ico-angry-disguisted-emoticon:before {
		content: "\e70e";
	}
