
  /*!
   *  Material Design Iconic Font 1.0.1 by Sergey Kupletsky (@zavoloklom) - http://zavoloklom.github.io/material-design-iconic-font/
   *  License - https://github.com/zavoloklom/material-design-iconic-font/blob/gh-pages/License.md (Attribution-ShareAlike 4.0 International license)
   */
  @font-face {
    font-family: 'md-icons';
    src: url('fonts/Material-Design-Iconic-Font.eot?v=1.0.1');
    src: url('fonts/Material-Design-Iconic-Font.eot?#iefix&v=1.0.1') format('embedded-opentype'), url('fonts/Material-Design-Iconic-Font.woff?v=1.0.1') format('woff'), url('fonts/Material-Design-Iconic-Font.ttf?v=1.0.1') format('truetype'), url('fonts/Material-Design-Iconic-Font.svg?v=1.0.1#Material-Design-Iconic-Font') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  [class^="md-"],
  [class*=" md-"] {
    display: inline-block;
    font: normal normal normal 14px/1 'md-icons';
    font-size: inherit;
    speak: none;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .md {
    line-height: inherit;
    vertical-align: bottom;
  }
  .md-lg {
    font-size: 1.5em;
    line-height: .5em;
    vertical-align: -35%;
  }
  .md-2x {
    font-size: 2em;
  }
  .md-3x {
    font-size: 3em;
  }
  .md-4x {
    font-size: 4em;
  }
  .md-5x {
    font-size: 5em;
  }
  .md-border {
    padding: .2em .25em .15em;
    border: solid 0.08em #808080;
    border-radius: .1em;
  }
  .md-border-circle {
    padding: .2em .25em .15em;
    border: solid 0.08em #808080;
    border-radius: 50%;
  }
  [class^="md-"].pull-left,
  [class*=" md-"].pull-left {
    float: left;
    margin-right: .3em;
  }
  [class^="md-"].pull-right,
  [class*=" md-"].pull-right {
    float: right;
    margin-left: .3em;
  }
  .md-spin {
    -webkit-animation: md-spin 1.5s infinite linear;
    animation: md-spin 1.5s infinite linear;
  }
  .md-spin-reverse {
    -webkit-animation: md-spin-reverse 1.5s infinite linear;
    animation: md-spin-reverse 1.5s infinite linear;
  }
  @-webkit-keyframes md-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
  @keyframes md-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
  @-webkit-keyframes md-spin-reverse {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(-359deg);
      transform: rotate(-359deg);
    }
  }
  @keyframes md-spin-reverse {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(-359deg);
      transform: rotate(-359deg);
    }
  }
  .md-rotate-90 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .md-rotate-180 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .md-rotate-270 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  .md-flip-horizontal {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
    -webkit-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
  }
  .md-flip-vertical {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
    -webkit-transform: scale(1, -1);
    -ms-transform: scale(1, -1);
    transform: scale(1, -1);
  }
  :root .md-rotate-90,
  :root .md-rotate-180,
  :root .md-rotate-270,
  :root .md-flip-horizontal,
  :root .md-flip-vertical {
    filter: none;
  }
  /* md-icons uses the Unicode Private Use Area (PUA) to ensure screen
     readers do not read off random characters that represent icons */
  /* If you do not want use all icons you can disable icon set here */
  .md-3d-rotation:before {
    content: "\f000";
  }
  .md-accessibility:before {
    content: "\f001";
  }
  .md-account-balance:before {
    content: "\f002";
  }
  .md-account-balance-wallet:before {
    content: "\f003";
  }
  .md-account-box:before {
    content: "\f004";
  }
  .md-account-child:before {
    content: "\f005";
  }
  .md-account-circle:before {
    content: "\f006";
  }
  .md-add-shopping-cart:before {
    content: "\f007";
  }
  .md-alarm:before {
    content: "\f008";
  }
  .md-alarm-add:before {
    content: "\f009";
  }
  .md-alarm-off:before {
    content: "\f00a";
  }
  .md-alarm-on:before {
    content: "\f00b";
  }
  .md-android:before {
    content: "\f00c";
  }
  .md-announcement:before {
    content: "\f00d";
  }
  .md-aspect-ratio:before {
    content: "\f00e";
  }
  .md-assessment:before {
    content: "\f00f";
  }
  .md-assignment:before {
    content: "\f010";
  }
  .md-assignment-ind:before {
    content: "\f011";
  }
  .md-assignment-late:before {
    content: "\f012";
  }
  .md-assignment-return:before {
    content: "\f013";
  }
  .md-assignment-returned:before {
    content: "\f014";
  }
  .md-assignment-turned-in:before {
    content: "\f015";
  }
  .md-autorenew:before {
    content: "\f016";
  }
  .md-backup:before {
    content: "\f017";
  }
  .md-book:before {
    content: "\f018";
  }
  .md-bookmark:before {
    content: "\f019";
  }
  .md-bookmark-outline:before {
    content: "\f01a";
  }
  .md-bug-report:before {
    content: "\f01b";
  }
  .md-cached:before {
    content: "\f01c";
  }
  .md-class:before {
    content: "\f01d";
  }
  .md-credit-card:before {
    content: "\f01e";
  }
  .md-dashboard:before {
    content: "\f01f";
  }
  .md-delete:before {
    content: "\f020";
  }
  .md-description:before {
    content: "\f021";
  }
  .md-dns:before {
    content: "\f022";
  }
  .md-done:before {
    content: "\f023";
  }
  .md-done-all:before {
    content: "\f024";
  }
  .md-event:before {
    content: "\f025";
  }
  .md-exit-to-app:before {
    content: "\f026";
  }
  .md-explore:before {
    content: "\f027";
  }
  .md-extension:before {
    content: "\f028";
  }
  .md-face-unlock:before {
    content: "\f029";
  }
  .md-favorite:before {
    content: "\f02a";
  }
  .md-favorite-outline:before {
    content: "\f02b";
  }
  .md-find-in-page:before {
    content: "\f02c";
  }
  .md-find-replace:before {
    content: "\f02d";
  }
  .md-flip-to-back:before {
    content: "\f02e";
  }
  .md-flip-to-front:before {
    content: "\f02f";
  }
  .md-get-app:before {
    content: "\f030";
  }
  .md-grade:before {
    content: "\f031";
  }
  .md-group-work:before {
    content: "\f032";
  }
  .md-help:before {
    content: "\f033";
  }
  .md-highlight-remove:before {
    content: "\f034";
  }
  .md-history:before {
    content: "\f035";
  }
  .md-home:before {
    content: "\f036";
  }
  .md-https:before {
    content: "\f037";
  }
  .md-info:before {
    content: "\f038";
  }
  .md-info-outline:before {
    content: "\f039";
  }
  .md-input:before {
    content: "\f03a";
  }
  .md-invert-colors:before {
    content: "\f03b";
  }
  .md-label:before {
    content: "\f03c";
  }
  .md-label-outline:before {
    content: "\f03d";
  }
  .md-language:before {
    content: "\f03e";
  }
  .md-launch:before {
    content: "\f03f";
  }
  .md-list:before {
    content: "\f040";
  }
  .md-lock:before {
    content: "\f041";
  }
  .md-lock-open:before {
    content: "\f042";
  }
  .md-lock-outline:before {
    content: "\f043";
  }
  .md-loyalty:before {
    content: "\f044";
  }
  .md-markunread-mailbox:before {
    content: "\f045";
  }
  .md-note-add:before {
    content: "\f046";
  }
  .md-open-in-browser:before {
    content: "\f047";
  }
  .md-open-in-new:before {
    content: "\f048";
  }
  .md-open-with:before {
    content: "\f049";
  }
  .md-pageview:before {
    content: "\f04a";
  }
  .md-payment:before {
    content: "\f04b";
  }
  .md-perm-camera-mic:before {
    content: "\f04c";
  }
  .md-perm-contact-cal:before {
    content: "\f04d";
  }
  .md-perm-data-setting:before {
    content: "\f04e";
  }
  .md-perm-device-info:before {
    content: "\f04f";
  }
  .md-perm-identity:before {
    content: "\f050";
  }
  .md-perm-media:before {
    content: "\f051";
  }
  .md-perm-phone-msg:before {
    content: "\f052";
  }
  .md-perm-scan-wifi:before {
    content: "\f053";
  }
  .md-picture-in-picture:before {
    content: "\f054";
  }
  .md-polymer:before {
    content: "\f055";
  }
  .md-print:before {
    content: "\f056";
  }
  .md-query-builder:before {
    content: "\f057";
  }
  .md-question-answer:before {
    content: "\f058";
  }
  .md-receipt:before {
    content: "\f059";
  }
  .md-redeem:before {
    content: "\f05a";
  }
  .md-report-problem:before {
    content: "\f05b";
  }
  .md-restore:before {
    content: "\f05c";
  }
  .md-room:before {
    content: "\f05d";
  }
  .md-schedule:before {
    content: "\f05e";
  }
  .md-search:before {
    content: "\f05f";
  }
  .md-settings:before {
    content: "\f060";
  }
  .md-settings-applications:before {
    content: "\f061";
  }
  .md-settings-backup-restore:before {
    content: "\f062";
  }
  .md-settings-bluetooth:before {
    content: "\f063";
  }
  .md-settings-cell:before {
    content: "\f064";
  }
  .md-settings-display:before {
    content: "\f065";
  }
  .md-settings-ethernet:before {
    content: "\f066";
  }
  .md-settings-input-antenna:before {
    content: "\f067";
  }
  .md-settings-input-component:before {
    content: "\f068";
  }
  .md-settings-input-composite:before {
    content: "\f069";
  }
  .md-settings-input-hdmi:before {
    content: "\f06a";
  }
  .md-settings-input-svideo:before {
    content: "\f06b";
  }
  .md-settings-overscan:before {
    content: "\f06c";
  }
  .md-settings-phone:before {
    content: "\f06d";
  }
  .md-settings-power:before {
    content: "\f06e";
  }
  .md-settings-remote:before {
    content: "\f06f";
  }
  .md-settings-voice:before {
    content: "\f070";
  }
  .md-shop:before {
    content: "\f071";
  }
  .md-shopping-basket:before {
    content: "\f072";
  }
  .md-shopping-cart:before {
    content: "\f073";
  }
  .md-shop-two:before {
    content: "\f074";
  }
  .md-speaker-notes:before {
    content: "\f075";
  }
  .md-spellcheck:before {
    content: "\f076";
  }
  .md-star-rate:before {
    content: "\f077";
  }
  .md-stars:before {
    content: "\f078";
  }
  .md-store:before {
    content: "\f079";
  }
  .md-subject:before {
    content: "\f07a";
  }
  .md-swap-horiz:before {
    content: "\f07b";
  }
  .md-swap-vert:before {
    content: "\f07c";
  }
  .md-swap-vert-circle:before {
    content: "\f07d";
  }
  .md-system-update-tv:before {
    content: "\f07e";
  }
  .md-tab:before {
    content: "\f07f";
  }
  .md-tab-unselected:before {
    content: "\f080";
  }
  .md-theaters:before {
    content: "\f081";
  }
  .md-thumb-down:before {
    content: "\f082";
  }
  .md-thumbs-up-down:before {
    content: "\f083";
  }
  .md-thumb-up:before {
    content: "\f084";
  }
  .md-toc:before {
    content: "\f085";
  }
  .md-today:before {
    content: "\f086";
  }
  .md-track-changes:before {
    content: "\f087";
  }
  .md-translate:before {
    content: "\f088";
  }
  .md-trending-down:before {
    content: "\f089";
  }
  .md-trending-neutral:before {
    content: "\f08a";
  }
  .md-trending-up:before {
    content: "\f08b";
  }
  .md-turned-in:before {
    content: "\f08c";
  }
  .md-turned-in-not:before {
    content: "\f08d";
  }
  .md-verified-user:before {
    content: "\f08e";
  }
  .md-view-agenda:before {
    content: "\f08f";
  }
  .md-view-array:before {
    content: "\f090";
  }
  .md-view-carousel:before {
    content: "\f091";
  }
  .md-view-column:before {
    content: "\f092";
  }
  .md-view-day:before {
    content: "\f093";
  }
  .md-view-headline:before {
    content: "\f094";
  }
  .md-view-list:before {
    content: "\f095";
  }
  .md-view-module:before {
    content: "\f096";
  }
  .md-view-quilt:before {
    content: "\f097";
  }
  .md-view-stream:before {
    content: "\f098";
  }
  .md-view-week:before {
    content: "\f099";
  }
  .md-visibility:before {
    content: "\f09a";
  }
  .md-visibility-off:before {
    content: "\f09b";
  }
  .md-wallet-giftcard:before {
    content: "\f09c";
  }
  .md-wallet-membership:before {
    content: "\f09d";
  }
  .md-wallet-travel:before {
    content: "\f09e";
  }
  .md-work:before {
    content: "\f09f";
  }
  .md-error:before {
    content: "\f0a0";
  }
  .md-warning:before {
    content: "\f0a1";
  }
  .md-album:before {
    content: "\f0a2";
  }
  .md-av-timer:before {
    content: "\f0a3";
  }
  .md-closed-caption:before {
    content: "\f0a4";
  }
  .md-equalizer:before {
    content: "\f0a5";
  }
  .md-explicit:before {
    content: "\f0a6";
  }
  .md-fast-forward:before {
    content: "\f0a7";
  }
  .md-fast-rewind:before {
    content: "\f0a8";
  }
  .md-games:before {
    content: "\f0a9";
  }
  .md-hearing:before {
    content: "\f0aa";
  }
  .md-high-quality:before {
    content: "\f0ab";
  }
  .md-loop:before {
    content: "\f0ac";
  }
  .md-mic:before {
    content: "\f0ad";
  }
  .md-mic-none:before {
    content: "\f0ae";
  }
  .md-mic-off:before {
    content: "\f0af";
  }
  .md-movie:before {
    content: "\f0b0";
  }
  .md-my-library-add:before {
    content: "\f0b1";
  }
  .md-my-library-books:before {
    content: "\f0b2";
  }
  .md-my-library-music:before {
    content: "\f0b3";
  }
  .md-new-releases:before {
    content: "\f0b4";
  }
  .md-not-interested:before {
    content: "\f0b5";
  }
  .md-pause:before {
    content: "\f0b6";
  }
  .md-pause-circle-fill:before {
    content: "\f0b7";
  }
  .md-pause-circle-outline:before {
    content: "\f0b8";
  }
  .md-play-arrow:before {
    content: "\f0b9";
  }
  .md-play-circle-fill:before {
    content: "\f0ba";
  }
  .md-play-circle-outline:before {
    content: "\f0bb";
  }
  .md-playlist-add:before {
    content: "\f0bc";
  }
  .md-play-shopping-bag:before {
    content: "\f0bd";
  }
  .md-queue:before {
    content: "\f0be";
  }
  .md-queue-music:before {
    content: "\f0bf";
  }
  .md-radio:before {
    content: "\f0c0";
  }
  .md-recent-actors:before {
    content: "\f0c1";
  }
  .md-repeat:before {
    content: "\f0c2";
  }
  .md-repeat-one:before {
    content: "\f0c3";
  }
  .md-replay:before {
    content: "\f0c4";
  }
  .md-shuffle:before {
    content: "\f0c5";
  }
  .md-skip-next:before {
    content: "\f0c6";
  }
  .md-skip-previous:before {
    content: "\f0c7";
  }
  .md-snooze:before {
    content: "\f0c8";
  }
  .md-stop:before {
    content: "\f0c9";
  }
  .md-subtitles:before {
    content: "\f0ca";
  }
  .md-surround-sound:before {
    content: "\f0cb";
  }
  .md-videocam:before {
    content: "\f0cc";
  }
  .md-videocam-off:before {
    content: "\f0cd";
  }
  .md-video-collection:before {
    content: "\f0ce";
  }
  .md-volume-down:before {
    content: "\f0cf";
  }
  .md-volume-mute:before {
    content: "\f0d0";
  }
  .md-volume-off:before {
    content: "\f0d1";
  }
  .md-volume-up:before {
    content: "\f0d2";
  }
  .md-web:before {
    content: "\f0d3";
  }
  .md-business:before {
    content: "\f0d4";
  }
  .md-call:before {
    content: "\f0d5";
  }
  .md-call-end:before {
    content: "\f0d6";
  }
  .md-call-made:before {
    content: "\f0d7";
  }
  .md-call-merge:before {
    content: "\f0d8";
  }
  .md-call-missed:before {
    content: "\f0d9";
  }
  .md-call-received:before {
    content: "\f0da";
  }
  .md-call-split:before {
    content: "\f0db";
  }
  .md-chat:before {
    content: "\f0dc";
  }
  .md-clear-all:before {
    content: "\f0dd";
  }
  .md-comment:before {
    content: "\f0de";
  }
  .md-contacts:before {
    content: "\f0df";
  }
  .md-dialer-sip:before {
    content: "\f0e0";
  }
  .md-dialpad:before {
    content: "\f0e1";
  }
  .md-dnd-on:before {
    content: "\f0e2";
  }
  .md-email:before {
    content: "\f0e3";
  }
  .md-forum:before {
    content: "\f0e4";
  }
  .md-import-export:before {
    content: "\f0e5";
  }
  .md-invert-colors-off:before {
    content: "\f0e6";
  }
  .md-invert-colors-on:before {
    content: "\f0e7";
  }
  .md-live-help:before {
    content: "\f0e8";
  }
  .md-location-off:before {
    content: "\f0e9";
  }
  .md-location-on:before {
    content: "\f0ea";
  }
  .md-message:before {
    content: "\f0eb";
  }
  .md-messenger:before {
    content: "\f0ec";
  }
  .md-no-sim:before {
    content: "\f0ed";
  }
  .md-phone:before {
    content: "\f0ee";
  }
  .md-portable-wifi-off:before {
    content: "\f0ef";
  }
  .md-quick-contacts-dialer:before {
    content: "\f0f0";
  }
  .md-quick-contacts-mail:before {
    content: "\f0f1";
  }
  .md-ring-volume:before {
    content: "\f0f2";
  }
  .md-stay-current-landscape:before {
    content: "\f0f3";
  }
  .md-stay-current-portrait:before {
    content: "\f0f4";
  }
  .md-stay-primary-landscape:before {
    content: "\f0f5";
  }
  .md-stay-primary-portrait:before {
    content: "\f0f6";
  }
  .md-swap-calls:before {
    content: "\f0f7";
  }
  .md-textsms:before {
    content: "\f0f8";
  }
  .md-voicemail:before {
    content: "\f0f9";
  }
  .md-vpn-key:before {
    content: "\f0fa";
  }
  .md-add:before {
    content: "\f0fb";
  }
  .md-add-box:before {
    content: "\f0fc";
  }
  .md-add-circle:before {
    content: "\f0fd";
  }
  .md-add-circle-outline:before {
    content: "\f0fe";
  }
  .md-archive:before {
    content: "\f0ff";
  }
  .md-backspace:before {
    content: "\f100";
  }
  .md-block:before {
    content: "\f101";
  }
  .md-clear:before {
    content: "\f102";
  }
  .md-content-copy:before {
    content: "\f103";
  }
  .md-content-cut:before {
    content: "\f104";
  }
  .md-content-paste:before {
    content: "\f105";
  }
  .md-create:before {
    content: "\f106";
  }
  .md-drafts:before {
    content: "\f107";
  }
  .md-filter-list:before {
    content: "\f108";
  }
  .md-flag:before {
    content: "\f109";
  }
  .md-forward:before {
    content: "\f10a";
  }
  .md-gesture:before {
    content: "\f10b";
  }
  .md-inbox:before {
    content: "\f10c";
  }
  .md-link:before {
    content: "\f10d";
  }
  .md-mail:before {
    content: "\f10e";
  }
  .md-markunread:before {
    content: "\f10f";
  }
  .md-redo:before {
    content: "\f110";
  }
  .md-remove:before {
    content: "\f111";
  }
  .md-remove-circle:before {
    content: "\f112";
  }
  .md-remove-circle-outline:before {
    content: "\f113";
  }
  .md-reply:before {
    content: "\f114";
  }
  .md-reply-all:before {
    content: "\f115";
  }
  .md-report:before {
    content: "\f116";
  }
  .md-save:before {
    content: "\f117";
  }
  .md-select-all:before {
    content: "\f118";
  }
  .md-send:before {
    content: "\f119";
  }
  .md-sort:before {
    content: "\f11a";
  }
  .md-text-format:before {
    content: "\f11b";
  }
  .md-undo:before {
    content: "\f11c";
  }
  .md-access-alarm:before {
    content: "\f11d";
  }
  .md-access-alarms:before {
    content: "\f11e";
  }
  .md-access-time:before {
    content: "\f11f";
  }
  .md-add-alarm:before {
    content: "\f120";
  }
  .md-airplanemode-off:before {
    content: "\f121";
  }
  .md-airplanemode-on:before {
    content: "\f122";
  }
  .md-battery-20:before {
    content: "\f123";
  }
  .md-battery-30:before {
    content: "\f124";
  }
  .md-battery-50:before {
    content: "\f125";
  }
  .md-battery-60:before {
    content: "\f126";
  }
  .md-battery-80:before {
    content: "\f127";
  }
  .md-battery-90:before {
    content: "\f128";
  }
  .md-battery-alert:before {
    content: "\f129";
  }
  .md-battery-charging-20:before {
    content: "\f12a";
  }
  .md-battery-charging-30:before {
    content: "\f12b";
  }
  .md-battery-charging-50:before {
    content: "\f12c";
  }
  .md-battery-charging-60:before {
    content: "\f12d";
  }
  .md-battery-charging-80:before {
    content: "\f12e";
  }
  .md-battery-charging-90:before {
    content: "\f12f";
  }
  .md-battery-charging-full:before {
    content: "\f130";
  }
  .md-battery-full:before {
    content: "\f131";
  }
  .md-battery-std:before {
    content: "\f132";
  }
  .md-battery-unknown:before {
    content: "\f133";
  }
  .md-bluetooth:before {
    content: "\f134";
  }
  .md-bluetooth-connected:before {
    content: "\f135";
  }
  .md-bluetooth-disabled:before {
    content: "\f136";
  }
  .md-bluetooth-searching:before {
    content: "\f137";
  }
  .md-brightness-auto:before {
    content: "\f138";
  }
  .md-brightness-high:before {
    content: "\f139";
  }
  .md-brightness-low:before {
    content: "\f13a";
  }
  .md-brightness-medium:before {
    content: "\f13b";
  }
  .md-data-usage:before {
    content: "\f13c";
  }
  .md-developer-mode:before {
    content: "\f13d";
  }
  .md-devices:before {
    content: "\f13e";
  }
  .md-dvr:before {
    content: "\f13f";
  }
  .md-gps-fixed:before {
    content: "\f140";
  }
  .md-gps-not-fixed:before {
    content: "\f141";
  }
  .md-gps-off:before {
    content: "\f142";
  }
  .md-location-disabled:before {
    content: "\f143";
  }
  .md-location-searching:before {
    content: "\f144";
  }
  .md-multitrack-audio:before {
    content: "\f145";
  }
  .md-network-cell:before {
    content: "\f146";
  }
  .md-network-wifi:before {
    content: "\f147";
  }
  .md-nfc:before {
    content: "\f148";
  }
  .md-now-wallpaper:before {
    content: "\f149";
  }
  .md-now-widgets:before {
    content: "\f14a";
  }
  .md-screen-lock-landscape:before {
    content: "\f14b";
  }
  .md-screen-lock-portrait:before {
    content: "\f14c";
  }
  .md-screen-lock-rotation:before {
    content: "\f14d";
  }
  .md-screen-rotation:before {
    content: "\f14e";
  }
  .md-sd-storage:before {
    content: "\f14f";
  }
  .md-settings-system-daydream:before {
    content: "\f150";
  }
  .md-signal-cellular-0-bar:before {
    content: "\f151";
  }
  .md-signal-cellular-1-bar:before {
    content: "\f152";
  }
  .md-signal-cellular-2-bar:before {
    content: "\f153";
  }
  .md-signal-cellular-3-bar:before {
    content: "\f154";
  }
  .md-signal-cellular-4-bar:before {
    content: "\f155";
  }
  .md-signal-cellular-connected-no-internet-0-bar:before {
    content: "\f156";
  }
  .md-signal-cellular-connected-no-internet-1-bar:before {
    content: "\f157";
  }
  .md-signal-cellular-connected-no-internet-2-bar:before {
    content: "\f158";
  }
  .md-signal-cellular-connected-no-internet-3-bar:before {
    content: "\f159";
  }
  .md-signal-cellular-connected-no-internet-4-bar:before {
    content: "\f15a";
  }
  .md-signal-cellular-no-sim:before {
    content: "\f15b";
  }
  .md-signal-cellular-null:before {
    content: "\f15c";
  }
  .md-signal-cellular-off:before {
    content: "\f15d";
  }
  .md-signal-wifi-0-bar:before {
    content: "\f15e";
  }
  .md-signal-wifi-1-bar:before {
    content: "\f15f";
  }
  .md-signal-wifi-2-bar:before {
    content: "\f160";
  }
  .md-signal-wifi-3-bar:before {
    content: "\f161";
  }
  .md-signal-wifi-4-bar:before {
    content: "\f162";
  }
  .md-signal-wifi-off:before {
    content: "\f163";
  }
  .md-storage:before {
    content: "\f164";
  }
  .md-usb:before {
    content: "\f165";
  }
  .md-wifi-lock:before {
    content: "\f166";
  }
  .md-wifi-tethering:before {
    content: "\f167";
  }
  .md-attach-file:before {
    content: "\f168";
  }
  .md-attach-money:before {
    content: "\f169";
  }
  .md-border-all:before {
    content: "\f16a";
  }
  .md-border-bottom:before {
    content: "\f16b";
  }
  .md-border-clear:before {
    content: "\f16c";
  }
  .md-border-color:before {
    content: "\f16d";
  }
  .md-border-horizontal:before {
    content: "\f16e";
  }
  .md-border-inner:before {
    content: "\f16f";
  }
  .md-border-left:before {
    content: "\f170";
  }
  .md-border-outer:before {
    content: "\f171";
  }
  .md-border-right:before {
    content: "\f172";
  }
  .md-border-style:before {
    content: "\f173";
  }
  .md-border-top:before {
    content: "\f174";
  }
  .md-border-vertical:before {
    content: "\f175";
  }
  .md-format-align-center:before {
    content: "\f176";
  }
  .md-format-align-justify:before {
    content: "\f177";
  }
  .md-format-align-left:before {
    content: "\f178";
  }
  .md-format-align-right:before {
    content: "\f179";
  }
  .md-format-bold:before {
    content: "\f17a";
  }
  .md-format-clear:before {
    content: "\f17b";
  }
  .md-format-color-fill:before {
    content: "\f17c";
  }
  .md-format-color-reset:before {
    content: "\f17d";
  }
  .md-format-color-text:before {
    content: "\f17e";
  }
  .md-format-indent-decrease:before {
    content: "\f17f";
  }
  .md-format-indent-increase:before {
    content: "\f180";
  }
  .md-format-italic:before {
    content: "\f181";
  }
  .md-format-line-spacing:before {
    content: "\f182";
  }
  .md-format-list-bulleted:before {
    content: "\f183";
  }
  .md-format-list-numbered:before {
    content: "\f184";
  }
  .md-format-paint:before {
    content: "\f185";
  }
  .md-format-quote:before {
    content: "\f186";
  }
  .md-format-size:before {
    content: "\f187";
  }
  .md-format-strikethrough:before {
    content: "\f188";
  }
  .md-format-textdirection-l-to-r:before {
    content: "\f189";
  }
  .md-format-textdirection-r-to-l:before {
    content: "\f18a";
  }
  .md-format-underline:before {
    content: "\f18b";
  }
  .md-functions:before {
    content: "\f18c";
  }
  .md-insert-chart:before {
    content: "\f18d";
  }
  .md-insert-comment:before {
    content: "\f18e";
  }
  .md-insert-drive-file:before {
    content: "\f18f";
  }
  .md-insert-emoticon:before {
    content: "\f190";
  }
  .md-insert-invitation:before {
    content: "\f191";
  }
  .md-insert-link:before {
    content: "\f192";
  }
  .md-insert-photo:before {
    content: "\f193";
  }
  .md-merge-type:before {
    content: "\f194";
  }
  .md-mode-comment:before {
    content: "\f195";
  }
  .md-mode-edit:before {
    content: "\f196";
  }
  .md-publish:before {
    content: "\f197";
  }
  .md-vertical-align-bottom:before {
    content: "\f198";
  }
  .md-vertical-align-center:before {
    content: "\f199";
  }
  .md-vertical-align-top:before {
    content: "\f19a";
  }
  .md-wrap-text:before {
    content: "\f19b";
  }
  .md-attachment:before {
    content: "\f19c";
  }
  .md-cloud:before {
    content: "\f19d";
  }
  .md-cloud-circle:before {
    content: "\f19e";
  }
  .md-cloud-done:before {
    content: "\f19f";
  }
  .md-cloud-download:before {
    content: "\f1a0";
  }
  .md-cloud-off:before {
    content: "\f1a1";
  }
  .md-cloud-queue:before {
    content: "\f1a2";
  }
  .md-cloud-upload:before {
    content: "\f1a3";
  }
  .md-file-download:before {
    content: "\f1a4";
  }
  .md-file-upload:before {
    content: "\f1a5";
  }
  .md-folder:before {
    content: "\f1a6";
  }
  .md-folder-open:before {
    content: "\f1a7";
  }
  .md-folder-shared:before {
    content: "\f1a8";
  }
  .md-cast:before {
    content: "\f1a9";
  }
  .md-cast-connected:before {
    content: "\f1aa";
  }
  .md-computer:before {
    content: "\f1ab";
  }
  .md-desktop-mac:before {
    content: "\f1ac";
  }
  .md-desktop-windows:before {
    content: "\f1ad";
  }
  .md-dock:before {
    content: "\f1ae";
  }
  .md-gamepad:before {
    content: "\f1af";
  }
  .md-headset:before {
    content: "\f1b0";
  }
  .md-headset-mic:before {
    content: "\f1b1";
  }
  .md-keyboard:before {
    content: "\f1b2";
  }
  .md-keyboard-alt:before {
    content: "\f1b3";
  }
  .md-keyboard-arrow-down:before {
    content: "\f1b4";
  }
  .md-keyboard-arrow-left:before {
    content: "\f1b5";
  }
  .md-keyboard-arrow-right:before {
    content: "\f1b6";
  }
  .md-keyboard-arrow-up:before {
    content: "\f1b7";
  }
  .md-keyboard-backspace:before {
    content: "\f1b8";
  }
  .md-keyboard-capslock:before {
    content: "\f1b9";
  }
  .md-keyboard-control:before {
    content: "\f1ba";
  }
  .md-keyboard-hide:before {
    content: "\f1bb";
  }
  .md-keyboard-return:before {
    content: "\f1bc";
  }
  .md-keyboard-tab:before {
    content: "\f1bd";
  }
  .md-keyboard-voice:before {
    content: "\f1be";
  }
  .md-laptop:before {
    content: "\f1bf";
  }
  .md-laptop-chromebook:before {
    content: "\f1c0";
  }
  .md-laptop-mac:before {
    content: "\f1c1";
  }
  .md-laptop-windows:before {
    content: "\f1c2";
  }
  .md-memory:before {
    content: "\f1c3";
  }
  .md-mouse:before {
    content: "\f1c4";
  }
  .md-phone-android:before {
    content: "\f1c5";
  }
  .md-phone-iphone:before {
    content: "\f1c6";
  }
  .md-phonelink:before {
    content: "\f1c7";
  }
  .md-phonelink-off:before {
    content: "\f1c8";
  }
  .md-security:before {
    content: "\f1c9";
  }
  .md-sim-card:before {
    content: "\f1ca";
  }
  .md-smartphone:before {
    content: "\f1cb";
  }
  .md-speaker:before {
    content: "\f1cc";
  }
  .md-tablet:before {
    content: "\f1cd";
  }
  .md-tablet-android:before {
    content: "\f1ce";
  }
  .md-tablet-mac:before {
    content: "\f1cf";
  }
  .md-tv:before {
    content: "\f1d0";
  }
  .md-watch:before {
    content: "\f1d1";
  }
  .md-add-to-photos:before {
    content: "\f1d2";
  }
  .md-adjust:before {
    content: "\f1d3";
  }
  .md-assistant-photo:before {
    content: "\f1d4";
  }
  .md-audiotrack:before {
    content: "\f1d5";
  }
  .md-blur-circular:before {
    content: "\f1d6";
  }
  .md-blur-linear:before {
    content: "\f1d7";
  }
  .md-blur-off:before {
    content: "\f1d8";
  }
  .md-blur-on:before {
    content: "\f1d9";
  }
  .md-brightness-1:before {
    content: "\f1da";
  }
  .md-brightness-2:before {
    content: "\f1db";
  }
  .md-brightness-3:before {
    content: "\f1dc";
  }
  .md-brightness-4:before {
    content: "\f1dd";
  }
  .md-brightness-5:before {
    content: "\f1de";
  }
  .md-brightness-6:before {
    content: "\f1df";
  }
  .md-brightness-7:before {
    content: "\f1e0";
  }
  .md-brush:before {
    content: "\f1e1";
  }
  .md-camera:before {
    content: "\f1e2";
  }
  .md-camera-alt:before {
    content: "\f1e3";
  }
  .md-camera-front:before {
    content: "\f1e4";
  }
  .md-camera-rear:before {
    content: "\f1e5";
  }
  .md-camera-roll:before {
    content: "\f1e6";
  }
  .md-center-focus-strong:before {
    content: "\f1e7";
  }
  .md-center-focus-weak:before {
    content: "\f1e8";
  }
  .md-collections:before {
    content: "\f1e9";
  }
  .md-colorize:before {
    content: "\f1ea";
  }
  .md-color-lens:before {
    content: "\f1eb";
  }
  .md-compare:before {
    content: "\f1ec";
  }
  .md-control-point:before {
    content: "\f1ed";
  }
  .md-control-point-duplicate:before {
    content: "\f1ee";
  }
  .md-crop:before {
    content: "\f1ef";
  }
  .md-crop-3-2:before {
    content: "\f1f0";
  }
  .md-crop-5-4:before {
    content: "\f1f1";
  }
  .md-crop-7-5:before {
    content: "\f1f2";
  }
  .md-crop-16-9:before {
    content: "\f1f3";
  }
  .md-crop-din:before {
    content: "\f1f4";
  }
  .md-crop-free:before {
    content: "\f1f5";
  }
  .md-crop-landscape:before {
    content: "\f1f6";
  }
  .md-crop-original:before {
    content: "\f1f7";
  }
  .md-crop-portrait:before {
    content: "\f1f8";
  }
  .md-crop-square:before {
    content: "\f1f9";
  }
  .md-dehaze:before {
    content: "\f1fa";
  }
  .md-details:before {
    content: "\f1fb";
  }
  .md-edit:before {
    content: "\f1fc";
  }
  .md-exposure:before {
    content: "\f1fd";
  }
  .md-exposure-minus-1:before {
    content: "\f1fe";
  }
  .md-exposure-minus-2:before {
    content: "\f1ff";
  }
  .md-exposure-zero:before {
    content: "\f200";
  }
  .md-exposure-plus-1:before {
    content: "\f201";
  }
  .md-exposure-plus-2:before {
    content: "\f202";
  }
  .md-filter:before {
    content: "\f203";
  }
  .md-filter-1:before {
    content: "\f204";
  }
  .md-filter-2:before {
    content: "\f205";
  }
  .md-filter-3:before {
    content: "\f206";
  }
  .md-filter-4:before {
    content: "\f207";
  }
  .md-filter-5:before {
    content: "\f208";
  }
  .md-filter-6:before {
    content: "\f209";
  }
  .md-filter-7:before {
    content: "\f20a";
  }
  .md-filter-8:before {
    content: "\f20b";
  }
  .md-filter-9:before {
    content: "\f20c";
  }
  .md-filter-9-plus:before {
    content: "\f20d";
  }
  .md-filter-b-and-w:before {
    content: "\f20e";
  }
  .md-filter-center-focus:before {
    content: "\f20f";
  }
  .md-filter-drama:before {
    content: "\f210";
  }
  .md-filter-frames:before {
    content: "\f211";
  }
  .md-filter-hdr:before {
    content: "\f212";
  }
  .md-filter-none:before {
    content: "\f213";
  }
  .md-filter-tilt-shift:before {
    content: "\f214";
  }
  .md-filter-vintage:before {
    content: "\f215";
  }
  .md-flare:before {
    content: "\f216";
  }
  .md-flash-auto:before {
    content: "\f217";
  }
  .md-flash-off:before {
    content: "\f218";
  }
  .md-flash-on:before {
    content: "\f219";
  }
  .md-flip:before {
    content: "\f21a";
  }
  .md-gradient:before {
    content: "\f21b";
  }
  .md-grain:before {
    content: "\f21c";
  }
  .md-grid-off:before {
    content: "\f21d";
  }
  .md-grid-on:before {
    content: "\f21e";
  }
  .md-hdr-off:before {
    content: "\f21f";
  }
  .md-hdr-on:before {
    content: "\f220";
  }
  .md-hdr-strong:before {
    content: "\f221";
  }
  .md-hdr-weak:before {
    content: "\f222";
  }
  .md-healing:before {
    content: "\f223";
  }
  .md-image:before {
    content: "\f224";
  }
  .md-image-aspect-ratio:before {
    content: "\f225";
  }
  .md-iso:before {
    content: "\f226";
  }
  .md-landscape:before {
    content: "\f227";
  }
  .md-leak-add:before {
    content: "\f228";
  }
  .md-leak-remove:before {
    content: "\f229";
  }
  .md-lens:before {
    content: "\f22a";
  }
  .md-looks:before {
    content: "\f22b";
  }
  .md-looks-1:before {
    content: "\f22c";
  }
  .md-looks-2:before {
    content: "\f22d";
  }
  .md-looks-3:before {
    content: "\f22e";
  }
  .md-looks-4:before {
    content: "\f22f";
  }
  .md-looks-5:before {
    content: "\f230";
  }
  .md-looks-6:before {
    content: "\f231";
  }
  .md-loupe:before {
    content: "\f232";
  }
  .md-movie-creation:before {
    content: "\f233";
  }
  .md-nature:before {
    content: "\f234";
  }
  .md-nature-people:before {
    content: "\f235";
  }
  .md-navigate-before:before {
    content: "\f236";
  }
  .md-navigate-next:before {
    content: "\f237";
  }
  .md-palette:before {
    content: "\f238";
  }
  .md-panorama:before {
    content: "\f239";
  }
  .md-panorama-fisheye:before {
    content: "\f23a";
  }
  .md-panorama-horizontal:before {
    content: "\f23b";
  }
  .md-panorama-vertical:before {
    content: "\f23c";
  }
  .md-panorama-wide-angle:before {
    content: "\f23d";
  }
  .md-photo:before {
    content: "\f23e";
  }
  .md-photo-album:before {
    content: "\f23f";
  }
  .md-photo-camera:before {
    content: "\f240";
  }
  .md-photo-library:before {
    content: "\f241";
  }
  .md-portrait:before {
    content: "\f242";
  }
  .md-remove-red-eye:before {
    content: "\f243";
  }
  .md-rotate-left:before {
    content: "\f244";
  }
  .md-rotate-right:before {
    content: "\f245";
  }
  .md-slideshow:before {
    content: "\f246";
  }
  .md-straighten:before {
    content: "\f247";
  }
  .md-style:before {
    content: "\f248";
  }
  .md-switch-camera:before {
    content: "\f249";
  }
  .md-switch-video:before {
    content: "\f24a";
  }
  .md-tag-faces:before {
    content: "\f24b";
  }
  .md-texture:before {
    content: "\f24c";
  }
  .md-timelapse:before {
    content: "\f24d";
  }
  .md-timer:before {
    content: "\f24e";
  }
  .md-timer-3:before {
    content: "\f24f";
  }
  .md-timer-10:before {
    content: "\f250";
  }
  .md-timer-auto:before {
    content: "\f251";
  }
  .md-timer-off:before {
    content: "\f252";
  }
  .md-tonality:before {
    content: "\f253";
  }
  .md-transform:before {
    content: "\f254";
  }
  .md-tune:before {
    content: "\f255";
  }
  .md-wb-auto:before {
    content: "\f256";
  }
  .md-wb-cloudy:before {
    content: "\f257";
  }
  .md-wb-incandescent:before {
    content: "\f258";
  }
  .md-wb-irradescent:before {
    content: "\f259";
  }
  .md-wb-sunny:before {
    content: "\f25a";
  }
  .md-beenhere:before {
    content: "\f25b";
  }
  .md-directions:before {
    content: "\f25c";
  }
  .md-directions-bike:before {
    content: "\f25d";
  }
  .md-directions-bus:before {
    content: "\f25e";
  }
  .md-directions-car:before {
    content: "\f25f";
  }
  .md-directions-ferry:before {
    content: "\f260";
  }
  .md-directions-subway:before {
    content: "\f261";
  }
  .md-directions-train:before {
    content: "\f262";
  }
  .md-directions-transit:before {
    content: "\f263";
  }
  .md-directions-walk:before {
    content: "\f264";
  }
  .md-flight:before {
    content: "\f265";
  }
  .md-hotel:before {
    content: "\f266";
  }
  .md-layers:before {
    content: "\f267";
  }
  .md-layers-clear:before {
    content: "\f268";
  }
  .md-local-airport:before {
    content: "\f269";
  }
  .md-local-atm:before {
    content: "\f26a";
  }
  .md-local-attraction:before {
    content: "\f26b";
  }
  .md-local-bar:before {
    content: "\f26c";
  }
  .md-local-cafe:before {
    content: "\f26d";
  }
  .md-local-car-wash:before {
    content: "\f26e";
  }
  .md-local-convenience-store:before {
    content: "\f26f";
  }
  .md-local-drink:before {
    content: "\f270";
  }
  .md-local-florist:before {
    content: "\f271";
  }
  .md-local-gas-station:before {
    content: "\f272";
  }
  .md-local-grocery-store:before {
    content: "\f273";
  }
  .md-local-hospital:before {
    content: "\f274";
  }
  .md-local-hotel:before {
    content: "\f275";
  }
  .md-local-laundry-service:before {
    content: "\f276";
  }
  .md-local-library:before {
    content: "\f277";
  }
  .md-local-mall:before {
    content: "\f278";
  }
  .md-local-movies:before {
    content: "\f279";
  }
  .md-local-offer:before {
    content: "\f27a";
  }
  .md-local-parking:before {
    content: "\f27b";
  }
  .md-local-pharmacy:before {
    content: "\f27c";
  }
  .md-local-phone:before {
    content: "\f27d";
  }
  .md-local-pizza:before {
    content: "\f27e";
  }
  .md-local-play:before {
    content: "\f27f";
  }
  .md-local-post-office:before {
    content: "\f280";
  }
  .md-local-print-shop:before {
    content: "\f281";
  }
  .md-local-restaurant:before {
    content: "\f282";
  }
  .md-local-see:before {
    content: "\f283";
  }
  .md-local-shipping:before {
    content: "\f284";
  }
  .md-local-taxi:before {
    content: "\f285";
  }
  .md-location-history:before {
    content: "\f286";
  }
  .md-map:before {
    content: "\f287";
  }
  .md-my-location:before {
    content: "\f288";
  }
  .md-navigation:before {
    content: "\f289";
  }
  .md-pin-drop:before {
    content: "\f28a";
  }
  .md-place:before {
    content: "\f28b";
  }
  .md-rate-review:before {
    content: "\f28c";
  }
  .md-restaurant-menu:before {
    content: "\f28d";
  }
  .md-satellite:before {
    content: "\f28e";
  }
  .md-store-mall-directory:before {
    content: "\f28f";
  }
  .md-terrain:before {
    content: "\f290";
  }
  .md-traffic:before {
    content: "\f291";
  }
  .md-apps:before {
    content: "\f292";
  }
  .md-cancel:before {
    content: "\f293";
  }
  .md-arrow-drop-down-circle:before {
    content: "\f294";
  }
  .md-arrow-drop-down:before {
    content: "\f295";
  }
  .md-arrow-drop-up:before {
    content: "\f296";
  }
  .md-arrow-back:before {
    content: "\f297";
  }
  .md-arrow-forward:before {
    content: "\f298";
  }
  .md-check:before {
    content: "\f299";
  }
  .md-close:before {
    content: "\f29a";
  }
  .md-chevron-left:before {
    content: "\f29b";
  }
  .md-chevron-right:before {
    content: "\f29c";
  }
  .md-expand-less:before {
    content: "\f29d";
  }
  .md-expand-more:before {
    content: "\f29e";
  }
  .md-fullscreen:before {
    content: "\f29f";
  }
  .md-fullscreen-exit:before {
    content: "\f2a0";
  }
  .md-menu:before {
    content: "\f2a1";
  }
  .md-more-horiz:before {
    content: "\f2a2";
  }
  .md-more-vert:before {
    content: "\f2a3";
  }
  .md-refresh:before {
    content: "\f2a4";
  }
  .md-unfold-less:before {
    content: "\f2a5";
  }
  .md-unfold-more:before {
    content: "\f2a6";
  }
  .md-adb:before {
    content: "\f2a7";
  }
  .md-bluetooth-audio:before {
    content: "\f2a8";
  }
  .md-disc-full:before {
    content: "\f2a9";
  }
  .md-dnd-forwardslash:before {
    content: "\f2aa";
  }
  .md-do-not-disturb:before {
    content: "\f2ab";
  }
  .md-drive-eta:before {
    content: "\f2ac";
  }
  .md-event-available:before {
    content: "\f2ad";
  }
  .md-event-busy:before {
    content: "\f2ae";
  }
  .md-event-note:before {
    content: "\f2af";
  }
  .md-folder-special:before {
    content: "\f2b0";
  }
  .md-mms:before {
    content: "\f2b1";
  }
  .md-more:before {
    content: "\f2b2";
  }
  .md-network-locked:before {
    content: "\f2b3";
  }
  .md-phone-bluetooth-speaker:before {
    content: "\f2b4";
  }
  .md-phone-forwarded:before {
    content: "\f2b5";
  }
  .md-phone-in-talk:before {
    content: "\f2b6";
  }
  .md-phone-locked:before {
    content: "\f2b7";
  }
  .md-phone-missed:before {
    content: "\f2b8";
  }
  .md-phone-paused:before {
    content: "\f2b9";
  }
  .md-play-download:before {
    content: "\f2ba";
  }
  .md-play-install:before {
    content: "\f2bb";
  }
  .md-sd-card:before {
    content: "\f2bc";
  }
  .md-sim-card-alert:before {
    content: "\f2bd";
  }
  .md-sms:before {
    content: "\f2be";
  }
  .md-sms-failed:before {
    content: "\f2bf";
  }
  .md-sync:before {
    content: "\f2c0";
  }
  .md-sync-disabled:before {
    content: "\f2c1";
  }
  .md-sync-problem:before {
    content: "\f2c2";
  }
  .md-system-update:before {
    content: "\f2c3";
  }
  .md-tap-and-play:before {
    content: "\f2c4";
  }
  .md-time-to-leave:before {
    content: "\f2c5";
  }
  .md-vibration:before {
    content: "\f2c6";
  }
  .md-voice-chat:before {
    content: "\f2c7";
  }
  .md-vpn-lock:before {
    content: "\f2c8";
  }
  .md-cake:before {
    content: "\f2c9";
  }
  .md-domain:before {
    content: "\f2ca";
  }
  .md-location-city:before {
    content: "\f2cb";
  }
  .md-mood:before {
    content: "\f2cc";
  }
  .md-notifications-none:before {
    content: "\f2cd";
  }
  .md-notifications:before {
    content: "\f2ce";
  }
  .md-notifications-off:before {
    content: "\f2cf";
  }
  .md-notifications-on:before {
    content: "\f2d0";
  }
  .md-notifications-paused:before {
    content: "\f2d1";
  }
  .md-pages:before {
    content: "\f2d2";
  }
  .md-party-mode:before {
    content: "\f2d3";
  }
  .md-group:before {
    content: "\f2d4";
  }
  .md-group-add:before {
    content: "\f2d5";
  }
  .md-people:before {
    content: "\f2d6";
  }
  .md-people-outline:before {
    content: "\f2d7";
  }
  .md-person:before {
    content: "\f2d8";
  }
  .md-person-add:before {
    content: "\f2d9";
  }
  .md-person-outline:before {
    content: "\f2da";
  }
  .md-plus-one:before {
    content: "\f2db";
  }
  .md-poll:before {
    content: "\f2dc";
  }
  .md-public:before {
    content: "\f2dd";
  }
  .md-school:before {
    content: "\f2de";
  }
  .md-share:before {
    content: "\f2df";
  }
  .md-whatshot:before {
    content: "\f2e0";
  }
  .md-check-box:before {
    content: "\f2e1";
  }
  .md-check-box-outline-blank:before {
    content: "\f2e2";
  }
  .md-radio-button-off:before {
    content: "\f2e3";
  }
  .md-radio-button-on:before {
    content: "\f2e4";
  }
  .md-star:before {
    content: "\f2e5";
  }
  .md-star-half:before {
    content: "\f2e6";
  }
  .md-star-outline:before {
    content: "\f2e7";
  }
