#appbar {

  #back-to-dash {
    border-right: 1px solid rgba(255, 255, 255, 0.25) !important;
    i {
      font-size: 18px;
      vertical-align: middle;
      margin-right: 5px;
    }
    span {
      vertical-align: middle;
    }
  }

}