#beach-lineup-modal {
  $modalBackgroundColor: hsl(0, 0%, 99%);
  $modalBorderColor: hsl(0, 0%, 99%);
  $modalColor: hsl(0, 0%, 0%);

  $selectDisabledBackgroundColor: hsl(0, 0%, 90%);

  $teamContainerBackgroundColor: hsl(0, 9%, 97%);
  $teamHeaderCellLabelColor: hsl(0, 0%, 41%);
  $teamTableSetRowBandedBackgroundColor: hsl(0, 0%, 95%);
  $teamPlayerDividerLineColor: hsl(0, 0%, 0%);
  $teamTablePlayerNumberColor: hsl(0, 0%, 0%);

  $primary-vm-blue: hsl(200, 69%, 53%);

  $inputHeight: 30px;

  position: relative;
  background-color: $modalBackgroundColor;
  outline-width: 0px;
  border: 2px solid $modalBorderColor;
  border-radius: 4px;
  color: $modalColor;

  select[disabled] {
    background-color: $selectDisabledBackgroundColor;
    cursor: not-allowed;
  }

  radio[disabled] {
    cursor: not-allowed;
  }

  .modal-header {
    border-width: 0px;
  }

  .modal-title {

  }

  .modal-close {
    position: absolute;
    top: 0;
    right: 0;
    width: 25px;
    text-align: center;
    line-height: 25px;
    font-size: 20px;
    color: $modalColor;
    cursor: pointer;

    &:hover {
      color: $primary-vm-blue;
    }
  }

  .modal-body {

  }

  .analyst-functions {
    margin-bottom: 10px;
  }

  .load-current-roster-button {
    float: right;
  }

  .modal-footer {
    border-width: 0px;
  }

  #beach-lineup-modal-player-container {

  }

  .team-container {
    display: inline-block;
    float: left;
    width: 50%;
    background-color: $teamContainerBackgroundColor;
    min-width: 400px;
  }

  .team-header {
    text-align: center;
    padding-left: 40px;
  }

  .team-header-cell-label {
    color: $teamHeaderCellLabelColor;
    vertical-align: middle;
  }

  .team-header-cell-text {
    font-size: 20px;
    vertical-align: middle;
  }

  .team-table {
    display: table;
    table-layout: fixed;
    width: 100%;
  }

  .team-table-header {
    display: table-row;
  }

  .team-table-header-cell {
    display: table-cell;
    text-align: center;
  }

  .team-table-header-cell-text {

  }

  .team-table-set-row {
    display: table-row;
    height: 40px;

    &:nth-child(2n + 1) {
      background-color: $teamTableSetRowBandedBackgroundColor;
    }
  }

  .team-table-set-row-header {
    display: table-cell;
    width: 40px;
    vertical-align: middle;
    text-align: right;
  }

  .team-table-set-row-cell {
    display: table-cell;
    vertical-align: middle;
    padding: 0 5px;

    &:nth-child(1n + 3) {
      border-left: 1px solid $teamPlayerDividerLineColor;
    }
  }

  .team-table-set-row-cell-player-select {
    width: 78%;
    float: left;
    height: $inputHeight;
    margin-right: 2%;
  }

  .team-table-set-row-cell-player-number-input {
    color: $teamTablePlayerNumberColor;
    width: 20%;
    float: left;
    height: $inputHeight;
    text-align: center;
  }

  .team-table-set-row-two {
    display: table-row;
    height: 20px;
  }

  .team-table-set-row-cell-position-header {
    width: 49%;
    float: left;
    height: 20px;
    text-align: center;
    margin-right: 1%;
  }

  .team-table-set-row-cell-secondary-position-header {
    width: 49%;
    float: left;
    height: 20px;
    text-align: center;
    margin-left: 1%;
  }

  .team-table-set-row-cell-position-select {
    width: 49%;
    float: left;
    height: $inputHeight;
    margin-right: 1%;
  }

  .team-table-set-row-cell-secondary-position-select {
    width: 49%;
    float: left;
    height: $inputHeight;
    margin-left: 1%;
  }

  option {

  }

  #beach-lineup-modal-serving-team-container {
    margin: 5px 0;
  }

  .serving-container {
    width: 50%;
    background-color: $teamContainerBackgroundColor;
    width: 400px;
    margin: 0 auto;
  }

  .serving-header {
    text-align: center;
    padding-left: 40px;
  }

  .serving-header-cell-label {
    color: $teamHeaderCellLabelColor;
    vertical-align: middle;
  }

  .serving-header-cell-text {
    font-size: 20px;
    vertical-align: middle;
  }

  .serving-table {
    display: table;
    table-layout: fixed;
    width: 100%;
  }

  .serving-table-header {
    display: table-row;
  }

  .serving-table-header-cell {
    display: table-cell;
    text-align: center;
  }

  .serving-table-header-cell-text {

  }

  .serving-table-set-row {
    display: table-row;
    height: 40px;

    &:nth-child(2n + 0) {
      background-color: $teamTableSetRowBandedBackgroundColor;
    }
  }

  .serving-table-set-row-header {
    display: table-cell;
    width: 40px;
    vertical-align: middle;
    text-align: right;
  }

  .serving-table-set-row-cell {
    position: relative;
    display: table-cell;
    vertical-align: middle;
    padding: 0 5px;

    &:nth-child(1n + 3) {
      border-left: 1px solid $teamPlayerDividerLineColor;
    }
  }

  .serving-table-set-row-clear {
    position: absolute;
    text-align: center;
    line-height: 20px;
    width: 20px;
    height: 20px;
    top: 0;
    bottom: 0;
    right: 20px;
    margin: auto 0;
    background-color: hsl(0, 0%, 40%);
    color: white;
    border-radius: 10px;
    font-weight: 800;
    font-size: 16px;

    &:hover {
      cursor: pointer;
      background-color: hsl(0, 100%, 40%);
    }
  }

  .serving-table-set-row-cell-player-select {
    width: 78%;
    float: left;
    height: $inputHeight;
    margin-right: 2%;
  }

  .serving-table-set-row-cell-player-number-input {
    color: $teamTablePlayerNumberColor;
    width: 20%;
    float: left;
    height: $inputHeight;
    text-align: center;
  }

  .serving-table-set-row-cell-position-select {
    width: 49%;
    float: left;
    height: $inputHeight;
    margin-right: 1%;
  }

  .serving-table-set-row-cell-secondary-position-select {
    width: 49%;
    float: left;
    height: $inputHeight;
    margin-left: 1%;
  }
}
