
.ui-select-match-text.pull-left {
  float: none !important;
}

.ui-select-match-text > span {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
}

#app-frame {
  position: fixed;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  &.login,
  &.login-remove {
    #appbar {
      z-index: 0;
    }
  }
  &.nav-hidden {
    #navbar {
      left: -$navWidth;
      -webkit-transition-delay: 0s;
      -moz-transition-delay: 0s;
      -ms-transition-delay: 0s;
      transition-delay: 0s;

    }
    #content-wrap {
      left: 0;
      -webkit-transition-delay: 0s;
      -moz-transition-delay: 0s;
      -ms-transition-delay: 0s;
      transition-delay: 0s;
      #appbar {
        .module-title {
          padding-left: 60px;
        }
      }
    }
  }
  &.nav-hidden-remove {
    #navbar {
      -webkit-transition-delay: 0s;
      -moz-transition-delay: 0s;
      -ms-transition-delay: 0s;
      transition-delay: 0s;
      .logo {
        .toggle-nav {
          -webkit-transition-delay: 0s;
          -moz-transition-delay: 0s;
          -ms-transition-delay: 0s;
          transition-delay: 0s;
        }
      }
    }
    #content-wrap {
      -webkit-transition-delay: 0s;
      -moz-transition-delay: 0s;
      -ms-transition-delay: 0s;
      transition-delay: 0s;
    }
  }
  #appbar {
    height: $appbarHeight;
    background-color: $appbarColor;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 200;
    -moz-box-shadow: $shadow-layer-1;
    -webkit-box-shadow: $shadow-layer-1;
    box-shadow: $shadow-layer-1;
    -webkit-transition: height 0.5s, margin 0.25s, -webkit-box-shadow 0.5s;
    -moz-transition: height 0.5s, margin 0.25s, -moz-box-shadow 0.5s;
    -ms-transition: height 0.5s, margin 0.25s, -ms-box-shadow 0.5s;
    transition: height 0.5s, margin 0.25s, box-shadow 0.5s;
    .toggle-nav {
      margin: 0;
      color: white;
      padding: 18px 24px;
      display: inline-block;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: .175em;
      font-weight: 300;
      i.ico {
        font-size: 25px;
      }
    }
    .logo {
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      color: #fff;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: .175em;
      font-weight: 400;
      text-rendering: optimizeLegibility;
      -webkit-font-smoothing: antialiased;
      width: 100%;
      height: 60px;
      -webkit-transition: .5s;
      -moz-transition: .5s;
      -ms-transition: .5s;
      transition: .5s;
      .logo-inside {
        -webkit-transition: .5s;
        -moz-transition: .5s;
        -ms-transition: .5s;
        transition: .5s;
        text-align: center;
        display: block;
        width: auto;
        padding: 10px 0;
        min-width: 100%;
        min-width: 0;
        position: absolute;
        top: 0;
        // left: 60px;
        left: 15px; // added 5-18-16
        margin: 0 auto;
        img {
          width: 150px;
          -webkit-transition: .5s;
          -moz-transition: .5s;
          -ms-transition: .5s;
          transition: .5s;
        }
      }
    }
    .game-title {
      margin: 0;
      color: white;
      padding: 24px $module-horz-padding;
      display: inline-block;
      font-size: 13px;
      text-transform: uppercase;
      letter-spacing: .175em;
      font-weight: 300;
      -webkit-transition: .5s;
      -moz-transition: .5s;
      -ms-transition: .5s;
      transition: .5s;
      position: absolute;
      top: 0;
      right: 150px;
      bottom: 0;
      left: 150px;
      text-align: center;
    }
    button {
      color: white;
      font-size: 14px;
      text-transform: uppercase;
      background: transparent;
      border: 0;
      border-left: 1px solid rgba(255, 255, 255, 0.25);
      display: inline-block;
      padding: 20px 24px;
      height: $appbarHeight;
      position: relative;
      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }
      &:active {
        background: rgba(0, 0, 0, 0.1);
        color: rgba(255, 255, 255, 0.75);
      }
    }
    .menu-wrap {
      position: relative;
      &:hover {
        .menu {
          display: block;
        }
      }
      .menu {
        position: absolute;
        top: 100%;
        right: 0;
        background-color: white;
        min-width: 200px;
        -moz-border-radius: 0 0 3px 3px;
        -webkit-border-radius: 0 0 3px 3px;
        border-radius: 0 0 3px 3px;
        display: none;
        ul {
          padding: 5px 0;
          margin: 0;
          list-style: none;
          li {
            a {
              display: block;
              padding: 10px 15px;
              line-height: 1.2;
              &:hover {
                background-color: $gray-lighter;
                text-decoration: none;
              }
            }
          }
        }
      }
    }
    .ico {
      font-size: 24px;
    }
  }

  div#analysis-pages {
    #content-wrap {
      -webkit-transition: .5s;
      -moz-transition: .5s;
      -ms-transition: .5s;
      transition: .5s;
      -webkit-transition-delay: .5s;
      transition-delay: .5s;
      position: absolute;
      top: $appbarHeight;
      right: 0;
      bottom: 0;
      left: 0;
      #content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        .toolbar {
          background-color: $toolbarColor;
          height: $toolbarHeight;
          position: relative;
          z-index: 10;
          box-shadow: 0 2px 0 rgba(0, 0, 0, 0.1);
          padding: 15px $module-horz-padding;
        }
        .sidebar {
          position: absolute;
          top: 0;
          // left: 0;
          right: 0;
          // bottom: 0;
          height: 50%;
          width: $sidebarWidth;
          background-color: #f3f3f3;
          z-index: 10;
          -moz-box-shadow: $shadow-layer-1;
          -webkit-box-shadow: $shadow-layer-1;
          box-shadow: $shadow-layer-1;
          overflow: hidden;
          .titlebar {
            background-color: #445a65;
            color: white;
            -moz-box-shadow: $shadow-layer-1;
            -webkit-box-shadow: $shadow-layer-1;
            box-shadow: $shadow-layer-1;
            padding: 18px 24px;
            height: 60px;
            position: relative;
            z-index: 10;
            .set-num {
              float: left;
            }
            .set-score {
              float: right;
            }
          }
          .sidebar-content {
            position: absolute;
            top: 60px;
            right: 0;
            bottom: 60px;
            left: 0;
            .rallies {
              list-style: none;
              padding: 0;
              margin: 0;
              .rally {
                //margin-bottom: 20px;
                border-bottom: 1px solid #DADADA;
                .rally-head {
                  padding: 12px 24px;
                  height: 40px;
                  background-color: #e8e8e8;
                  cursor:pointer;
                  .rally-num {
                    float: left;
                  }
                  .point {
                    float: right;
                  }
                }
                .touches {
                  background-color: white;
                  list-style: none;
                  margin: 0;
                  .touch {
                    padding: 12px 0;
                    border-bottom: 1px solid $gray-light;
                    font-size: 13px;
                    -webkit-transition: .25s box-shadow;
                    -moz-transition: .25s box-shadow;
                    -ms-transition: .25s box-shadow;
                    transition: .25s box-shadow;
                    -webkit-transition: background-color 0.5s ease;
                    -moz-transition: background-color 0.5s ease;
                    -ms-transition: background-color 0.5s ease;
                    transition: background-color 0.5s ease;
                    &:hover {
                      // background-color: #f7f7f7;
                      outline: 3px solid #37a2db;
                    }
                    &.active {
                      margin-left: -34px;
                      margin-right: -34px;
                      padding-left: 24px;
                      padding-right: 24px;
                      position: relative;
                      -moz-box-shadow: $shadow-layer-2;
                      -webkit-box-shadow: $shadow-layer-2;
                      box-shadow: $shadow-layer-2;
                      border-top: 1px solid $gray-light;
                      border-bottom: 1px solid $gray-light;
                      margin-top: -1px;
                    }
                    &.unread {
                      font-weight: 600;
                      .indicator {
                        background-color: $blue;
                      }
                    }
                    &.read {
                      color: #999;
                    }
                    button.btn {
                      text-transform: none;
                      font-size: inherit;
                      padding: 0;
                      font-size: 13px;
                      text-align: left;
                      span {
                        margin: 0;
                        padding: 0;
                      }
                    }
                    .read-ind {
                      padding-left: 0;
                      .indicator {
                        width: 10px;
                        height: 10px;
                        -moz-border-radius: 50%;
                        -webkit-border-radius: 50%;
                        border-radius: 50%;
                        border: 2px solid $blue;
                        margin: 0px 0; // CHANGED ON 9-20-16 FROM 5px 0;
                      }
                    }

                    .player {
                      text-align: right;
                      padding-right: 0;
                      input[type=text],
                      input[type=number] {
                        text-align: right;
                      }
                    }
                    input[type=text],
                    input[type=number] {
                      padding: 0;
                      margin: 0;
                      border: 0;
                      width: 100%;
                      background: transparent;
                      height: auto;
                      -moz-box-shadow: 0 0 0 transparent;
                      -webkit-box-shadow: 0 0 0 transparent;
                      box-shadow: 0 0 0 transparent;
                      line-height: 1.5;
                      font-size: 13px;
                    }
                  }
                  .ui-select-bootstrap {
                    border: 1px solid #d2d2d2;
                    .btn {
                      padding: 10px 21px 10px 7px;
                      font-size: 12px;
                    }
                  }
                  .touch input[type=text] {
                    padding: 7px;
                    border: 1px solid #d2d2d2;
                    background-color: #f2f2f2;
                  }
                  .ui-select-match {
                    span .ng-binding {
                      margin-top: -1px;
                    }
                  }
                  .btn-primary {
                    margin-top: 10px;
                    margin-bottom: 10px;
                  }
                  .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
                    padding-left: 0px;
                  }
                  li.touch {
                    // padding: 12px 30px;
                    padding: 12px 15px 12px 30px; // Changed 5-23-16
                  }
                }
              }
            }
          }
          .actionbar {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 60px;
            background-color: white;
            -moz-box-shadow: $shadow-layer-2;
            -webkit-box-shadow: $shadow-layer-2;
            box-shadow: $shadow-layer-2;
            font-size: 0;
            button {
              background-color: white;
              border: 0;
              text-transform: uppercase;
              padding: 20px 0;
              width: 50%;
              font-size: 14px;
              margin-left: -1px;
              border-left: 1px solid $gray-light;
            }
          }
        }
        .scrollable {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          overflow: scroll;
          overflow-x: hidden;
          overflow-y: auto;
        }
        .col-container {
          margin: 20px 0px;
          padding: 0;
        }
        .padded-container {
          padding: $module-horz-padding;
        }
        .main {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          // left: $sidebarWidth;
          right: $sidebarWidth;
          overflow: hidden; // added 5-13-16
          .video-wrap {
            position: absolute;
            top: 0;
            right: 0;
            // bottom: 38%;
            bottom: 0;
            left: 0;
            background-color: black;
            .video-overlay {
              z-index: 1;
              position: absolute;
              top: 0px;
              width: 100%;
              height: 60px;
              text-align: center;
              background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%); /* FF3.6+ */
              background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0.6)), color-stop(100%, rgba(0, 0, 0, 0))); /* Chrome,Safari4+ */
              background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%); /* Chrome10+,Safari5.1+ */
              background: -o-linear-gradient(top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%); /* Opera 11.10+ */
              background: -ms-linear-gradient(top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%); /* IE10+ */
              background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%); /* W3C */
              filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#99000000', endColorstr='#00000000', GradientType=0); /* IE6-9 */
              span {
                margin: 10px;
                margin-right: 20px;
                margin-left: 20px;
                display: inline-block;
                font-size: 14px;
                color: white;
              }
            }
          }
          .court-wrap {
            position: absolute;
            top: 62%;
            right: 0;
            bottom: 0;
            left: 0;
            .court-xy {
              width: 72%;
              height: 100%;
              position: relative;
              background-color: #2E2C33;
              .scoreboard {
                float: left;
                width: 39%;
                height: 100%;
                background-color: #2E2C33;
                border-right: 1px solid rgba(0, 0, 0, 0.5);
                position: relative;
                .sub-libero-btns {
                  height: 12%;
                  width: 100%;
                  div {
                    cursor: pointer;
                    text-align: center;
                    color: white;
                    height: 100%;
                    background-color: #1A191D;
                    position: relative;
                    -moz-transition: all .3s;
                    -o-transition: all .3s;
                    -webkit-transition: all .3s;
                    transition: all .3s;
                    &:hover {
                      background-color: #141316;
                    }
                    &:active {
                      background-color: #0B0A0C;
                      -moz-transition: all .1s;
                      -o-transition: all .1s;
                      -webkit-transition: all .1s;
                      transition: all .1s;
                    }
                    &:first-child {
                      border-right: 1px solid #2E2C33;
                    }
                    &:last-child {
                      border-left: 1px solid #2E2C33;
                    }
                    span {
                      position: absolute;
                      top: 0px;
                      bottom: 0px;
                      left: 0px;
                      right: 0px;
                      margin: auto;
                      height: 18px;
                    }
                  }
                }
                .team {
                  height: 38%;
                  width: 100%;
                  color: white;
                  padding: 5%;
                  position: relative;
                  .team-logo-container {
                    position: absolute;
                    top: 0px;
                    bottom: 0px;
                    margin: auto;
                    float: left;
                    padding-bottom: 20%;
                    height: 0px;
                    width: 18%;
                    .team-logo {
                      width: 100%;
                      padding-bottom: 100%;
                      border-radius: 50%;
                      -webkit-border-radius: 50%;
                      -moz-border-radius: 50%;
                      overflow: hidden;
                      background-position: center;
                      -webkit-background-size: cover;
                      -moz-background-size: cover;
                      -o-background-size: cover;
                      background-size: cover;
                    }
                  }
                  .name {
                    width: 90%;
                    height: 18px;
                    float: left;
                    text-align: left;
                    vertical-align: middle;
                    font-size: 18px;
                    font-weight: 100;
                    top: 50%;
                    margin-top: -10px;
                    position: relative;
                    padding-left: 25%

                  }
                  .score {
                    width: 10%;
                    height: 24px;
                    float: left;
                    vertical-align: middle;
                    text-align: right;
                    font-size: 24px;
                    font-weight: 300;
                    top: 50%;
                    margin-top: -15px;
                    position: relative;
                  }
                }
                button {
                  position: absolute;
                  left: 0px;
                  right: 0px;
                  margin: auto;
                  margin-top: -30px;
                  height: 60px;
                  width: 60px;
                  background-color: #1A191D;
                  border: 0;
                  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
                  color: white;
                  display: block;
                  padding: 0;
                  margin-left: auto;
                  margin-right: auto;
                  border-radius: 50%;
                  z-index: 100;
                  -webkit-border-radius: 50%;
                  -moz-border-radius: 50%;
                  -moz-transition: all .3s;
                  -o-transition: all .3s;
                  -webkit-transition: all .3s;
                  transition: all .3s;
                  &:hover {
                    background-color: #141316;
                  }
                  &:active {
                    background-color: #0B0A0C;
                    -moz-transition: all .1s;
                    -o-transition: all .1s;
                    -webkit-transition: all .1s;
                    transition: all .1s;
                  }
                  i {
                    font-size: 32px;
                    vertical-align: middle;
                  }
                }
                #media-time-out {
                  cursor: pointer;
                  text-align: center;
                  color: white;
                  height: 100%;
                  background-color: #1A191D;
                  position: relative;
                  -moz-transition: all .3s;
                  -o-transition: all .3s;
                  -webkit-transition: all .3s;
                  transition: all .3s;
                  padding: 8px;
                  white-space: nowrap;
                  height: 42px;
                  margin-top: -21px;
                  float: right;
                  border-radius: 8px;
                  margin-right: 8px;
                  overflow: hidden;
                  z-index: 1;
                  &:hover {
                    background-color: #141316;
                  }
                  &:active {
                    background-color: #0B0A0C;
                    -moz-transition: all .1s;
                    -o-transition: all .1s;
                    -webkit-transition: all .1s;
                    transition: all .1s;
                  }
                }
              }
              .court {
                width: 61%;
                height: 100%;
                float: left;
                vertical-align: middle;
                text-align: center;
                padding: 0px;
                -moz-transition: all .3s;
                -o-transition: all .3s;
                -webkit-transition: all .3s;
                transition: all .3s;
                &:hover {
                  background-color: #29272D;
                }
                .court-bounds-wrap {
                  display: inline-block;
                  height: 100%;
                  width: 100%; /* 100/56.25 = 1.778 */
                  max-width: 100%;
                  max-height: 100%; /* 16/9 = 1.778 */
                  margin: 0 auto !important;
                  padding: 0 !important;
                  list-style-type: none;
                  list-style-position: inside;
                  background-color: rgba(0, 0, 0, 0);
                  position: relative;
                  .court-bounds {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    border: 1px solid white;
                    transform: rotateX(73deg) translate(0%, -15%) scale(2.2);
                    transform-style: preserve-3d;
                    background-color: #c19d62;
                    background-image: url(images/court-bg.jpg);
                    background-size: cover;
                    cursor: crosshair;
                    -moz-box-shadow: $shadow-layer-2;
                    -webkit-box-shadow: $shadow-layer-2;
                    box-shadow: $shadow-layer-2;
                    .side {
                      width: 100%;
                      height: 50%;
                      margin: 0;
                      pointer-events: none;
                    }
                    .side-1 {
                      border-bottom: 3px solid white;
                      position: relative;
                      .outside-bounds {
                        background-color: #357190;
                        opacity: .65;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 10%;
                        &:before {
                          content: '';
                          display: block;
                          position: absolute;
                          left: 0;
                          top: 0;
                          width: 5%;
                          height: 1000%;
                          background-color: #357190;
                        }
                        &:after {
                          content: '';
                          display: block;
                          position: absolute;
                          right: 0;
                          top: 0;
                          width: 5%;
                          height: 1000%;
                          background-color: #357190;
                        }
                      }
                      .side-inner {
                        border: 3px solid white;
                        border-bottom: 0;
                        position: absolute;
                        top: 10%;
                        right: 5%;
                        bottom: 0;
                        left: 5%;
                        .front-zone {
                          width: 100%;
                          height: 33%;
                        }
                        .back-zone {
                          width: 100%;
                          height: 66%;
                          border-bottom: 3px solid white;
                        }
                      }
                    }
                    .side-2 {
                      border-top: 3px solid white;
                      position: relative;
                      .outside-bounds {
                        background-color: #357190;
                        opacity: .65;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 10%;
                        &:before {
                          content: '';
                          display: block;
                          position: absolute;
                          left: 0;
                          bottom: 0;
                          width: 5%;
                          height: 1000%;
                          background-color: #357190;
                        }
                        &:after {
                          content: '';
                          display: block;
                          position: absolute;
                          right: 0;
                          bottom: 0;
                          width: 5%;
                          height: 1000%;
                          background-color: #357190;
                        }
                      }
                      .side-inner {
                        border: 3px solid white;
                        border-top: 0;
                        position: absolute;
                        top: 0;
                        right: 5%;
                        bottom: 10%;
                        left: 5%;
                        .front-zone {
                          width: 100%;
                          height: 33%;
                        }
                        .back-zone {
                          width: 100%;
                          height: 66%;
                          border-top: 3px solid white;
                        }
                      }
                    }
                  }
                  .ball {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 20px;
                    height: 20px;
                    margin: -10px 0 0 -10px;
                    background-image: url(images/volleyball.svg);
                    background-size: cover;
                    -moz-border-radius: 50%;
                    -webkit-border-radius: 50%;
                    border-radius: 50%;
                    -moz-box-shadow: $shadow-layer-2;
                    -webkit-box-shadow: $shadow-layer-2;
                    box-shadow: $shadow-layer-2;
                  }
                }
              }
            }
            .court-z {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 72%;
              background-color: #1a191d;
              overflow: hidden;
              -moz-transition: all .3s;
              -o-transition: all .3s;
              -webkit-transition: all .3s;
              transition: all .3s;
              cursor: pointer;
              &:hover {
                background-color: #141316;
              }
              .net {
                position: absolute;
                bottom: 0;
                top: 0;
                height: 100%;
                width: 100%;
                background-image: url(images/net.svg);
                background-size: auto 100%;
                background-repeat: no-repeat;
                overflow: hidden;

              }
              .line {
                width: 100%;
                height: 2px;
                border-bottom: 2px dotted white;
                position: absolute;
                left: 0;
                top: 50%;
                cursor: ns-resize;
                .ball {
                  position: absolute;
                  bottom: -2px;
                  left: 50%;
                  width: 15px;
                  height: 15px;
                  margin: 0 0 0 -1px;
                  background-image: url(images/volleyball.svg);
                  background-size: cover;
                  -moz-border-radius: 50%;
                  -webkit-border-radius: 50%;
                  border-radius: 50%;
                  -moz-box-shadow: $shadow-layer-2;
                  -webkit-box-shadow: $shadow-layer-2;
                  box-shadow: $shadow-layer-2;
                }
                .height-display {
                  top: 0px;
                  position: absolute;
                  background-color: #2E2C33;
                  color: white;
                  padding: 5px 12px;
                  border-radius: 3px;
                  -moz-border-radius: 3px;
                  -webkit-border-radius: 3px;
                  margin-top: -15px;
                  left: 50%;
                  margin-left: 30px;
                  height: 31px;
                  &:before {
                    content: "";
                    border-style: solid;
                    border-width: 10px 15px 10px 0;
                    border-color: transparent #2E2C33 transparent transparent;
                    position: absolute;
                    left: -10px;
                  }
                }
              }

            }
            .edit-overlay {
              width: 72%;
              height: 100%;
              background-color: rgba(26, 25, 29, .65);
              position: absolute;
              bottom: 0px;
              right: 0px;
              cursor: pointer;
              -webkit-transition: all .5s;
              transition: all .5s;
              div {
                margin: auto;
                width: 80px;
                height: 80px;
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                border: 8px solid rgba(255, 255, 255, 0);
                padding: 16px;
                position: absolute;
                top: 0px;
                bottom: 0px;
                left: 0px;
                right: 0px;
                -webkit-transition: all .5s;
                transition: all .5s;
                i {
                  -webkit-transition: all .5s;
                  transition: all .5s;
                  color: rgba(255, 255, 255, 0);
                  font-size: 32px;
                }
              }
              &:hover {
                background-color: rgba(26, 25, 29, .87);
                div {
                  border: 8px solid rgba(255, 255, 255, .4);
                  i {
                    color: rgba(255, 255, 255, .4);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.portal-modal #right_column {
  position: relative;
}

.disabled-cover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  opacity: .8;
  cursor: not-allowed;
}

.table-cell input {
  width: 100%;
}

.triangle-wrapper {
  input {
    display: none;
  }
  i {
    font-size: 1.5em;
    transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    margin-right: -17px;
    margin-tOP: 6px;
    color: #FFDEB4;
    cursor: pointer;
  }
  input:checked + label i {
    color: #FF8F01;
  }
}

div#portal #portal-content-wide, .portal-modal #portal-content-wide {
  overflow: hidden;
}

.blue {
  background-color: #BEE8FF;
}
