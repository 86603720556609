#match-reset-modal-container {
  align-items: center;

  .better-checkbox {
    @extend %better-checkbox;
  }

  #match-id-area {
    display: flex;
    justify-content: space-evenly;
    textarea {
      max-width: 300px;
    }
  }


  #advanced-show-checkbox {
    margin-top: 30px;
  }

  #advanced-ops {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  #match-options {

  }

  #scoresheet-options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

}

/* Eventually get these into a shared library for re-use */
%better-checkbox {
  $md-checkbox-margin: 5px 0;
  $md-checkbox-checked-color: rgb(51, 122, 183);
  $md-checkbox-border-color: rgba(0, 0, 0, 0.54);
  $md-checkbox-size: 17px;
  $md-checkbox-padding: 4px;
  $md-checkmark-width: 2px;
  $md-checkmark-color: #fff;

  position: relative;
  margin: $md-checkbox-margin;

  label {
    cursor: pointer;
    &:before, &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
    }
    &:before {
      // box
      width: $md-checkbox-size;
      height: $md-checkbox-size;
      background: #fff;
      border: 2px solid $md-checkbox-border-color;
      border-radius: 2px;
      cursor: pointer;
      transition: background .3s;
    }

    &:after {
      // checkmark
    }
  }

  input[type="checkbox"] {
    outline: 0;
    margin-right: $md-checkbox-size - 10px;

    &:checked {
      + label:before {
        background: $md-checkbox-checked-color;
        border: none;
      }
      + label:after {

        $md-checkmark-size: $md-checkbox-size - 2*$md-checkbox-padding;

        transform: rotate(-45deg);

        top: ($md-checkbox-size / 2) - ($md-checkmark-size / 4) - $md-checkbox-size/10;
        left: $md-checkbox-padding;
        width: $md-checkmark-size;
        height: $md-checkmark-size / 2;

        border: $md-checkmark-width solid $md-checkmark-color;
        border-top-style: none;
        border-right-style: none;
      }
    }
  }
}

%better-radio-btn {
  $md-radio-checked-color: rgb(51, 122, 183);
  $md-radio-border-color: rgba(0, 0, 0, 0.54);
  $md-radio-size: 20px;
  $md-radio-checked-size: 10px;
  $md-radio-ripple-size: 15px;

  @keyframes ripple {
    0% {
      box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.0);
    }
    50% {
      box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0.1);
    }
    100% {
      box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0);
    }
  }

  .md-radio {
    margin: 16px 0;

    &.md-radio-inline {
      display: inline-block;
    }

    input[type="radio"] {
      display: none;
      &:checked + label:before {
        border-color: $md-radio-checked-color;
        animation: ripple 0.2s linear forwards;
      }
      &:checked + label:after {
        transform: scale(1);
      }
    }

    label {
      display: inline-block;
      height: $md-radio-size;
      position: relative;
      padding: 0 ($md-radio-size + 10px);
      margin-bottom: 0;
      cursor: pointer;
      vertical-align: bottom;
      &:before, &:after {
        position: absolute;
        content: '';
        border-radius: 50%;
        transition: all .3s ease;
        transition-property: transform, border-color;
      }
      &:before {
        left: 0;
        top: 0;
        width: $md-radio-size;
        height: $md-radio-size;
        border: 2px solid $md-radio-border-color;
      }
      &:after {
        top: $md-radio-size / 2 - $md-radio-checked-size / 2;
        left: $md-radio-size / 2 - $md-radio-checked-size / 2;
        width: $md-radio-checked-size;
        height: $md-radio-checked-size;
        transform: scale(0);
        background: $md-radio-checked-color;
      }
    }
  }
}

