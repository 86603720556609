/**
 * This is the main application stylesheet. It should include or import all
 * stylesheets used throughout the application as this is the only stylesheet in
 * the Gulp configuration that is automatically processed.
 */
@import '_variables';
@import '../../vendor/bootstrap-sass-official/assets/stylesheets/_bootstrap';
@import '_framework';
@import '_typography';
@import '_simplicicons';
@import '_md-icons';
@import '_ui';
@import '_loader';
@import '../../modules/auth/styles/auth';

//@import '../../vendor/lato/scss/lato';
@import 'styles-includes/select.min.css';
@import 'styles-includes/videogular.css';
@import 'styles-includes/hotkeys.min.css';
@import 'styles-includes/angular-ui-switch.css';
@import '../../modules/analysis/styles/video-player';
@import 'volleymetrics';

@import '../../modules/analysis/directives/virtualCourt/_virtualCourtStyle';
@import '../../modules/analysis/modals/beachLineupModal/_beachLineupModalStyle';
@import '../../modules/analysis/views/dashboard/historyModal/_historyModalStyle';
@import '../../modules/analysis/views/_analysisAppStyle';
@import '../../modules/analysis/views/header/_analysisHeaderStyle';
@import '../../modules/analysis/views/header/eventDetails/_eventDetailsStyle';
@import '../../modules/analysis/modals/lineupModal/_lineupModalStyle';
@import '../../modules/analysis/views/dashboard/_dashboardStyle';
@import '../../modules/analysis/views/dashboard/resetMatchModal/resetMatchModalStyle.scss';
@import '../../modules/auth/views/login/_loginStyle';
@import '../../modules/analysis/modals/substitutionModal/_substitutionModalStyle.scss';
